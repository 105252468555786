import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import {
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbList,
  BreadcrumbPage,
  BreadcrumbSeparator,
} from "components/ui/breadcrumb";

import { Button } from "components/ui/button";
import { useAppState } from "context/AppContext";
import AccountSelect from "../AccountSelect";

import { DataTableQuestionSubjects } from "./data";
import AddQuestionSubjectDialog from "./AddQuestionSubjectDialog";
import { QuestionSubject } from "types";
import apiClient from "api";

const QuestionSubjects = () => {
  const { state } = useAppState();
  const { agency, client, account } = state.admin;

  const [data, setData] = useState<QuestionSubject[]>([]);
  const [loading, setLoading] = useState(false);
  const [openAdd, setOpenAdd] = useState(false);

  useEffect(() => {
    setData([]);
    if (!account) return;
    const timeout = setTimeout(fetchQuestionSubjects, 200);
    return () => clearTimeout(timeout);
    // eslint-disable-next-line
  }, [account]);

  const fetchQuestionSubjects = async () => {
    setLoading(true);
    try {
      const { data } = await apiClient.get(
        "admin_creative_get_question_subjects",
        {
          headers: {
            request: JSON.stringify({ creative_id: account?.account_id }),
          },
        }
      );
      setData(data.message || []);
    } catch (error) {
      console.log("Error:", error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div>
      <Breadcrumb className="mb-4">
        <BreadcrumbList>
          <BreadcrumbItem>
            <Link to="/">Home</Link>
          </BreadcrumbItem>
          <BreadcrumbSeparator />
          <BreadcrumbItem>
            <Link to="/admin/agencies">{agency?.agency_name}</Link>
          </BreadcrumbItem>
          <BreadcrumbSeparator />
          <BreadcrumbItem>
            <Link to="/admin/clients">{client?.customer_name}</Link>
          </BreadcrumbItem>
          <BreadcrumbSeparator />
          <BreadcrumbItem>
            <Link to="/admin/accounts">{account?.account_name}</Link>
          </BreadcrumbItem>
          <BreadcrumbSeparator />
          <BreadcrumbItem>
            <BreadcrumbPage>Question subjects</BreadcrumbPage>
          </BreadcrumbItem>
        </BreadcrumbList>
      </Breadcrumb>

      <div className="space-y-2">
        <div className="flex justify-between items-center">
          <h3 className="scroll-m-20 text-2xl font-bold tracking-tight">
            Question subjects
          </h3>
          <AccountSelect />
        </div>
      </div>

      <div className="mt-8">
        <div className="flex justify-end">
          <Button size="sm" onClick={() => setOpenAdd(true)}>
            Add question subject
          </Button>
        </div>
      </div>

      <DataTableQuestionSubjects
        data={data}
        loading={loading}
        fetchData={fetchQuestionSubjects}
      />

      <AddQuestionSubjectDialog
        fetchData={fetchQuestionSubjects}
        open={openAdd}
        onOpenChange={setOpenAdd}
      />
    </div>
  );
};

export default QuestionSubjects;
