import { useEffect, useState } from "react";
import { RefreshCw } from "lucide-react";
import {
  Dialog,
  DialogClose,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from "components/ui/dialog";
import { Label } from "components/ui/label";
import { Button } from "components/ui/button";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "components/ui/select";
import { Input } from "components/ui/input";
import { useAppState } from "context/AppContext";
import { AvatarSchedule, Content, Avatar } from "types";
import apiClient from "api";
import { Textarea } from "components/ui/textarea";

type Props = {
  open: boolean;
  editData?: AvatarSchedule | null;
  fetchData: () => void;
  onOpenChange: (value: boolean) => void;
};

const AddAvatarScheduleDialog = ({
  open,
  editData,
  fetchData,
  onOpenChange,
}: Props) => {
  const { state } = useAppState();
  const { account } = state.admin;

  const [bio, setBio] = useState("");
  const [avatars, setAvatars] = useState<Avatar[]>([]);
  const [contentTypes, setContentTypes] = useState<Content[]>([]);
  const [contentList, setContentList] = useState<
    { content_id: number; description: string }[]
  >([]);
  const [loadingAvatar, setLoadingAvatar] = useState(false);
  const [submitting, setSubmitting] = useState(false);

  const [hoursBetween, setHoursBetween] = useState(0);

  const [avatar, setAvatar] = useState<Avatar | null>(null);
  const [contentType, setContentType] = useState<Content | null>(null);
  const [content, setContent] = useState<{
    content_id: number;
    description: string;
  } | null>(null);

  useEffect(() => {
    if (!editData) return;
    setBio(editData.bio);
    setHoursBetween(editData.hours_between);
    setAvatar(avatars.find((a) => a.avatar_id === editData.avatar_id) || null);

    setContentType(
      contentTypes.find((c) => c.content_type === editData.content_type) || null
    );

    setContent(
      contentList.find((c) => c.content_id === editData.content_id) || null
    );
  }, [editData, avatars, contentTypes, contentList]);

  useEffect(() => {
    if (!open) {
      setHoursBetween(0);
      setAvatar(null);
      setContentType(null);
      setContent(null);
      return;
    }
    fetchAvatars();
    fetchContentTypes();
    // eslint-disable-next-line
  }, [open]);

  useEffect(() => {
    if (!contentType) return;

    const getContent = async () => {
      try {
        const { data } = await apiClient.get(
          "/admin_creative_get_content_list",
          {
            headers: {
              request: JSON.stringify({
                content_type: contentType.content_type,
                creative_id: account?.account_id,
              }),
            },
          }
        );

        const list: { content_id: number; description: string }[] =
          data?.message || [];
        setContentList(list);
      } catch (error) {
        console.log("error:", error);
      } finally {
      }
    };

    getContent();
    // eslint-disable-next-line
  }, [contentType]);

  const fetchContentTypes = async () => {
    try {
      const { data } = await apiClient.get("/admin_creative_get_content_types");

      const content: Content[] = data?.message || [];
      setContentTypes(content);
    } catch (error) {
      console.error("Error:", error);
    } finally {
    }
  };

  const fetchAvatars = async () => {
    setLoadingAvatar(true);
    try {
      const { data } = await apiClient.get("/admin_creative_get_avatars", {
        headers: {
          request: JSON.stringify({ creative_id: account?.account_id }),
        },
      });

      const listData: Avatar[] = data?.message || [];
      setAvatars(listData);
    } catch (error) {
      console.error("Error:", error);
    } finally {
      setLoadingAvatar(false);
    }
  };

  const addAvatarSchedule = async (evt: React.FormEvent) => {
    evt.preventDefault();
    setSubmitting(true);
    if (Boolean(editData)) {
      try {
        await apiClient.post(
          "/admin_creative_update_avatar_schedule",
          {},
          {
            headers: {
              request: JSON.stringify({
                avatar_schedule_id: editData?.avatar_schedule_id,
                avatar_id: avatar?.avatar_id || 0,
                content_type: contentType?.content_type,
                content_id: content?.content_id || 0,
                hours_between: hoursBetween,
                bio,
              }),
            },
          }
        );
        onOpenChange(false);
        fetchData();
      } catch (error) {
        console.log("Error:", error);
      } finally {
        setSubmitting(false);
      }
    } else {
      try {
        await apiClient.post(
          "/admin_creative_add_avatar_schedule",
          {},
          {
            headers: {
              request: JSON.stringify({
                avatar_id: avatar?.avatar_id || 0,
                content_type: contentType?.content_type,
                content_id: content?.content_id || 0,
                hours_between: hoursBetween,
                bio,
              }),
            },
          }
        );
        onOpenChange(false);
        fetchData();
      } catch (error) {
        console.log("Error:", error);
      } finally {
        setSubmitting(false);
      }
    }
  };

  return (
    <>
      <Dialog open={open} onOpenChange={onOpenChange}>
        <DialogContent className="sm:max-w-[425px]">
          <DialogHeader>
            <DialogTitle>
              {!!editData ? "Edit" : "Add"} avatar schedule
            </DialogTitle>
          </DialogHeader>

          <form onSubmit={addAvatarSchedule} className="w-full grid gap-4">
            <div className="grid gap-2">
              <Label htmlFor="avatar">Avatar</Label>
              <div className="flex gap-2">
                <Select
                  required
                  value={String(avatar?.avatar_id || "")}
                  onValueChange={(value) => {
                    if (value === "no data") return;
                    const data = avatars.filter(
                      (av) => av.avatar_id === parseInt(value)
                    );
                    setAvatar(data[0] || null);
                  }}
                >
                  <SelectTrigger type="button" className="w-full">
                    <SelectValue
                      placeholder={
                        loadingAvatar ? "Loading..." : "Select avatar"
                      }
                    />
                  </SelectTrigger>
                  <SelectContent>
                    {avatars.length > 0 ? (
                      avatars.map((av) => (
                        <SelectItem
                          key={av.avatar_id}
                          value={String(av.avatar_id)}
                        >
                          {av.avatar_name}
                        </SelectItem>
                      ))
                    ) : (
                      <SelectItem value={"no data"}>No data</SelectItem>
                    )}
                  </SelectContent>
                </Select>
                {/* <Button size="sm" onClick={() => setOpenAddWP(true)}>
                <Plus className="size-4" />
              </Button> */}
              </div>
            </div>

            <div className="grid gap-2">
              <Label htmlFor="bio">Bio</Label>
              <Textarea
                id="bio"
                required
                rows={4}
                value={bio}
                onChange={(evt) => {
                  const val = evt.target.value;
                  setBio(val);
                }}
                className="resize-none"
              />
            </div>

            <div className="grid gap-2">
              <Label htmlFor="content-type">Content type</Label>
              <div className="flex gap-2">
                <Select
                  required
                  value={String(contentType?.content_type)}
                  onValueChange={(value) => {
                    if (value === "no data") return;
                    const content = contentTypes.filter(
                      (wp) => wp.content_type === parseInt(value)
                    );
                    setContentType(content[0] || null);
                    setContent(null);
                  }}
                >
                  <SelectTrigger type="button" className="w-full">
                    <SelectValue placeholder={"Select content type"} />
                  </SelectTrigger>
                  <SelectContent>
                    {contentTypes.length > 0 ? (
                      contentTypes.map((content) => (
                        <SelectItem
                          key={content.content_type}
                          value={String(content.content_type)}
                        >
                          {content.description}
                        </SelectItem>
                      ))
                    ) : (
                      <SelectItem value={"no data"}>No data</SelectItem>
                    )}
                  </SelectContent>
                </Select>
              </div>
            </div>

            {contentType && (
              <div className="grid gap-2">
                <Label htmlFor="content">Content</Label>
                <div className="flex gap-2">
                  <Select
                    value={String(content?.content_id)}
                    onValueChange={(value) => {
                      if (value === "no data") return;
                      const content = contentList.filter(
                        (d) => d.content_id === parseInt(value)
                      );
                      setContent(content[0] || null);
                    }}
                  >
                    <SelectTrigger type="button" className="w-full">
                      <SelectValue placeholder={"Select content"} />
                    </SelectTrigger>
                    <SelectContent>
                      {contentList.length > 0 ? (
                        contentList.map((content) => (
                          <SelectItem
                            key={content.content_id}
                            value={String(content.content_id)}
                          >
                            {content.description || content.content_id}
                          </SelectItem>
                        ))
                      ) : (
                        <SelectItem value={"no data"}>No data</SelectItem>
                      )}
                    </SelectContent>
                  </Select>
                </div>
              </div>
            )}

            <div className="grid gap-2">
              <Label htmlFor="hours">Hours between</Label>
              <Input
                required
                type="number"
                id="hours"
                value={hoursBetween || ""}
                onChange={(evt) => {
                  const val = evt.target.value;
                  if (parseInt(val) < 0) return;
                  setHoursBetween(parseInt(val));
                }}
              />
            </div>

            <DialogFooter>
              <DialogClose asChild>
                <Button
                  disabled={submitting}
                  type="button"
                  size="sm"
                  variant="outline"
                >
                  Cancel
                </Button>
              </DialogClose>
              <Button disabled={submitting} type="submit" size="sm">
                {submitting && (
                  <RefreshCw className="size-4 animate-spin mr-2" />
                )}{" "}
                Save
              </Button>
            </DialogFooter>
          </form>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default AddAvatarScheduleDialog;
