import { ColumnDef } from "@tanstack/react-table";
import { Link } from "react-router-dom";
// import { Checkbox } from "components/ui/checkbox";

import { DataTableColumnHeader } from "./data-table-column-header";
import { formatDateTime } from "utils/helper-methods";
import { OverviewPost } from "types";

export const columns: ColumnDef<OverviewPost>[] = [
  // {
  //   id: "select",
  //   header: ({ table }) => (
  //     <Checkbox
  //       checked={
  //         table.getIsAllPageRowsSelected() ||
  //         (table.getIsSomePageRowsSelected() && "indeterminate")
  //       }
  //       onCheckedChange={(value) => table.toggleAllPageRowsSelected(!!value)}
  //       aria-label="Select all"
  //       className="translate-y-[2px]"
  //     />
  //   ),
  //   cell: ({ row }) => (
  //     <Checkbox
  //       checked={row.getIsSelected()}
  //       onCheckedChange={(value) => row.toggleSelected(!!value)}
  //       aria-label="Select row"
  //       className="translate-y-[2px]"
  //     />
  //   ),
  //   enableSorting: false,
  //   enableHiding: false,
  // },
  {
    accessorKey: "post_id",
    header: ({ column }) => (
      <DataTableColumnHeader column={column} title="ID" />
    ),
    cell: ({ row }) => <div className="w-10">{row.getValue("post_id")}</div>,
    enableSorting: true,
    enableHiding: false,
  },
  {
    accessorKey: "title",
    header: ({ column }) => (
      <DataTableColumnHeader column={column} title="Title" />
    ),
    cell: ({ row }) => {
      return (
        <div className="flex space-x-2">
          <Link
            className="max-w-[300px] truncate hover:underline cursor-pointer"
            to={`/overview/${row.original.social_name.toLowerCase()}/post/${
              row.original.unique_post_id
            }`}
          >
            <span title={row.original.title} className="font-medium">
              {row.original.title}
            </span>
          </Link>
        </div>
      );
    },
    getUniqueValues: (row) => {
      return [row.title];
    },
    filterFn: (row, id, value) => {
      return row.original.title.toLowerCase().includes(value.toLowerCase());
    },
    enableColumnFilter: true,
    enableHiding: false,
  },
  {
    accessorKey: "date_posted",
    header: ({ column }) => (
      <DataTableColumnHeader column={column} title="Date posted" />
    ),
    cell: ({ row }) => {
      return (
        <div className="flex space-x-2">
          <span className="text-sm truncate font-medium">
            {formatDateTime(new Date(row.original.date_posted), false)}
          </span>
        </div>
      );
    },
    enableSorting: true,
    enableHiding: false,
  },
  {
    accessorKey: "click_thru_rate",
    header: ({ column }) => (
      <DataTableColumnHeader column={column} title="Click thru rate" />
    ),
    cell: ({ row }) => {
      return (
        <div className="flex space-x-2">
          <span className="text-sm truncate font-medium">
            {row.original.click_thru_rate}
          </span>
        </div>
      );
    },
    enableSorting: true,
    enableHiding: false,
  },
];
