import { useState, useEffect } from "react";
import { CopyIcon, RefreshCw } from "lucide-react";
import { Link } from "react-router-dom";
import {
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbList,
  BreadcrumbPage,
  BreadcrumbSeparator,
} from "components/ui/breadcrumb";
import { Input } from "components/ui/input";
import { Button } from "components/ui/button";
import { useToast } from "components/ui/use-toast";
import { useAppState } from "context/AppContext";
import AccountSelect from "../AccountSelect";
import { DataTableURLs } from "./data";
import apiClient from "api";
import { CreativeURL } from "types";

// type Account = {
//   account_id: number;
//   account_name: string;
// };

const RedirectBuilder = () => {
  const { state } = useAppState();
  const { agency, client, account } = state.admin;
  const { toast } = useToast();
  const [url, setUrl] = useState("");
  const [generatedUrl, setGeneratedUrl] = useState("");
  const [loading, setLoading] = useState(false);
  const [isGenerated, setIsGenerated] = useState(false);
  const [isGenerating, setIsGenerating] = useState(false);
  const [data, setData] = useState<CreativeURL[]>([]);

  useEffect(() => {
    const timeout = setTimeout(getUrls, 100);

    return () => clearTimeout(timeout);
    // eslint-disable-next-line
  }, [account]);

  const getUrls = async () => {
    if (!account) return;
    setLoading(true);
    try {
      const { data } = await apiClient.get("/admin_creative_get_urls", {
        headers: {
          request: JSON.stringify({
            creative_account_id: account.account_id,
          }),
        },
      });
      setData(data?.message || []);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.error("Error", error);
    }
  };

  const generateUrl = async () => {
    setIsGenerating(true);
    try {
      const { data } = await apiClient.post(
        "/admin_creative_add_url",
        {},
        {
          headers: {
            request: JSON.stringify({
              creative_account_id: account?.account_id,
              target_url: url,
            }),
          },
        }
      );

      setGeneratedUrl(data?.message[0].modified_url);
      setIsGenerated(true);
      setIsGenerating(false);
      getUrls();
    } catch (error) {
      setIsGenerating(false);
    }
  };

  const handleCopy = () => {
    if (generatedUrl) {
      navigator.clipboard.writeText(generatedUrl);
      toast({
        title: "URL copied",
      });
    }
  };

  return (
    <div>
      <Breadcrumb className="mb-4">
        <BreadcrumbList>
          <BreadcrumbItem>
            <Link to="/">Home</Link>
          </BreadcrumbItem>
          <BreadcrumbSeparator />
          <BreadcrumbItem>
            <Link to="/admin/agencies">{agency?.agency_name}</Link>
          </BreadcrumbItem>
          <BreadcrumbSeparator />
          <BreadcrumbItem>
            <Link to="/admin/clients">{client?.customer_name}</Link>
          </BreadcrumbItem>
          <BreadcrumbSeparator />
          <BreadcrumbItem>
            <BreadcrumbPage>Redirect builder</BreadcrumbPage>
          </BreadcrumbItem>
        </BreadcrumbList>
      </Breadcrumb>
      <div className="space-y-2">
        <div className="flex justify-between items-center">
          <h3 className="scroll-m-20 text-2xl font-bold tracking-tight">
            Redirect builder
          </h3>
          <AccountSelect />
        </div>
      </div>
      <div className="bg-background pt-8">
        <div className="flex items-center space-x-2 px-1">
          <Input
            className=" max-w-sm"
            value={url}
            onChange={(evt) => {
              setUrl(evt.target.value);
              setIsGenerated(false);
              setGeneratedUrl("");
            }}
            placeholder="Enter url"
            type="text"
          />

          <Button disabled={isGenerating || !url} onClick={generateUrl}>
            {isGenerating && <RefreshCw className="animate-spin size-4 mr-2" />}
            Generate
          </Button>
        </div>

        {isGenerated && (
          <div className="mt-4">
            <p className="font-medium text-sm pl-1 pb-2">Generared URL</p>
            <div className="flex items-center space-x-2 px-1">
              <Input
                className="max-w-sm"
                type="text"
                defaultValue={generatedUrl || ""}
                readOnly
              />
              <Button onClick={handleCopy} size="icon" variant="outline">
                <CopyIcon className="size-4" />
              </Button>
            </div>
          </div>
        )}

        <DataTableURLs data={data} loading={loading} />
      </div>
    </div>
  );
};

export default RedirectBuilder;
