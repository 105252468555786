import React, { useEffect } from "react";
import { Navigate, Outlet, useNavigate } from "react-router-dom";

import { useAppState } from "context/AppContext";
import { Toaster } from "components/ui/toaster";
import { SetupConnection } from "routes";
import useAxiosInterceptors from "api/useAxiosIntercepter";
// import { TOKEN_KEY } from "constants/constants";

const RootLayout: React.FC = () => {
  useAxiosInterceptors();
  const { state } = useAppState();
  const { isDarkMode } = state.settings;
  const { isAuthenticating, isAuthenticated } = state.auth;
  const { account_token } = state.user;
  const navigate = useNavigate();

  // const token = localStorage.getItem(TOKEN_KEY);

  useEffect(() => {
    if (!account_token) {
      const params = new URLSearchParams(window.location.search);
      if (params.size > 0) {
        const code = params.get("code");
        const error = params.get("error");
        const state = params.get("state");
        if (code || error || state) return;
        navigate("/");
      } else {
        navigate("/");
      }
    }

    // eslint-disable-next-line
  }, [account_token]);

  useEffect(() => {
    if (isDarkMode) {
      document.documentElement.classList.add("dark");
    } else {
      document.documentElement.classList.remove("dark");
    }
    // eslint-disable-next-line
  }, [isDarkMode]);

  if (isAuthenticating) {
    return (
      <div className="fixed inset-0 flex items-center justify-center bg-background z-50">
        <div className="flex flex-col justify-center items-center">
          <div className="size-12 border-4 border-t-4 border-muted border-t-primary rounded-full animate-spin"></div>
          <span className="text-center mt-4 text-primary font-medium">
            Please wait, Loading...
          </span>
        </div>
      </div>
    );
  }

  if (!isAuthenticated) {
    return <Navigate to="/login" />;
  }

  return (
    <div className="antialiased bg-background theme-brand">
      <Outlet />
      <Toaster />
      <SetupConnection />
    </div>
  );
};

export default RootLayout;
