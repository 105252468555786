import { useEffect, useState } from "react";
import { RefreshCw } from "lucide-react";
import {
  Dialog,
  DialogClose,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from "components/ui/dialog";

import { Label } from "components/ui/label";
import { Button } from "components/ui/button";
import { Textarea } from "components/ui/textarea";
import { useAppState } from "context/AppContext";
import apiClient from "api";
import { BlogSetting } from "types";

type Props = {
  open: boolean;
  editData?: BlogSetting | null;
  fetchData: () => void;
  onOpenChange: (value: boolean) => void;
};

const AddBlogSettingDialog = ({
  open,
  editData,
  fetchData,
  onOpenChange,
}: Props) => {
  const { state } = useAppState();
  const { account } = state.admin;

  // const [wpConnections, setWPConnections] = useState<WordpressConnection[]>([]);
  // const [wpCategories, setWPCategories] = useState<WordpressCategories[]>([]);
  // const [wpUsers, setWPUsers] = useState<AuthorList[]>([]);

  // const [openAddWP, setOpenAddWP] = useState(false);
  // const [loadingWp, setLoadingWp] = useState(false);
  const [submitting, setSubmitting] = useState(false);

  // const [hoursBetween, setHoursBetween] = useState(0);
  // const [loadingWpData, setLoadingWpData] = useState(false);
  const [description, setDescription] = useState("");
  // const [author, setAuhtor] = useState<AuthorList | null>(null);
  // const [category, setCategory] = useState<WordpressCategories | null>(null);
  // const [selectedWp, setSelectedWp] = useState<WordpressConnection | null>(
  //   null
  // );

  useEffect(() => {
    if (!editData) return;
    setDescription(editData.description);
  }, [editData]);

  // useEffect(() => {
  //   if (!selectedWp) return;

  //   const getUsersCategories = async () => {
  //     setLoadingWpData(true);
  //     try {
  //       const { data } = await apiClient.get("/creative_get_users_categories", {
  //         headers: {
  //           request: JSON.stringify({
  //             wp_connection_id: selectedWp.wp_connection_id,
  //             creative_id: account?.account_id,
  //           }),
  //         },
  //       });
  //       const categories: WordpressCategories[] =
  //         Object.values(data?.message?.categories) || [];
  //       const users: AuthorList[] = Object.values(data?.message?.users) || [];

  //       setWPCategories(categories);
  //       setWPUsers(users);
  //     } catch (error) {
  //       console.log("error:", error);
  //     } finally {
  //       setLoadingWpData(false);
  //     }
  //   };

  //   getUsersCategories();
  //   // eslint-disable-next-line
  // }, [selectedWp]);

  // const fetchWpConnections = async () => {
  //   setLoadingWp(true);
  //   try {
  //     const { data } = await apiClient.get(
  //       "/creative_get_wordpress_connections",
  //       {
  //         headers: {
  //           request: JSON.stringify({ creative_id: account?.account_id }),
  //         },
  //       }
  //     );

  //     const connections: WordpressConnection[] = data?.message || [];
  //     setWPConnections(connections);
  //   } catch (error) {
  //     console.error("Error fetching connections:", error);
  //   } finally {
  //     setLoadingWp(false);
  //   }
  // };

  const addBlogSetting = async (evt: React.FormEvent) => {
    evt.preventDefault();
    setSubmitting(true);
    if (Boolean(editData)) {
      try {
        await apiClient.post(
          "/admin_creative_update_blog_post_setting",
          {},
          {
            headers: {
              request: JSON.stringify({
                creative_id: account?.account_id,
                blog_setting_id: editData?.blog_post_settings_id,
                description,
              }),
            },
          }
        );
        onOpenChange(false);
        fetchData();
      } catch (error) {
        console.log("Error:", error);
      } finally {
        setSubmitting(false);
      }
    } else {
      try {
        await apiClient.post(
          "/admin_creative_add_blog_post_setting",
          {},
          {
            headers: {
              request: JSON.stringify({
                creative_id: account?.account_id,
                description,
              }),
            },
          }
        );
        onOpenChange(false);
        fetchData();
      } catch (error) {
        console.log("Error:", error);
      } finally {
        setSubmitting(false);
      }
    }
  };

  return (
    <>
      <Dialog open={open} onOpenChange={onOpenChange}>
        <DialogContent className="sm:max-w-[425px]">
          <DialogHeader>
            <DialogTitle>
              {!!editData ? "Edit" : "Add"} long form
            </DialogTitle>
          </DialogHeader>

          <form onSubmit={addBlogSetting} className="grid gap-4">
            {/* <div className="grid gap-2">
            <Label htmlFor="wordpress">Wordpress site</Label>
            <div className="flex gap-2">
              <Select
                value={String(selectedWp?.wp_connection_id || "")}
                onValueChange={(value) => {
                  if (value === "no data") return;
                  const wpConn = wpConnections.filter(
                    (wp) => wp.wp_connection_id === parseInt(value)
                  );
                  setSelectedWp(wpConn[0] || null);
                }}
              >
                <SelectTrigger type="button" className="w-full">
                  <SelectValue
                    placeholder={
                      loadingWp ? "Loading..." : "Select wordpress site"
                    }
                  />
                </SelectTrigger>
                <SelectContent>
                  {wpConnections.length > 0 ? (
                    wpConnections.map((wp) => (
                      <SelectItem
                        key={wp.wp_connection_id}
                        value={String(wp.wp_connection_id)}
                      >
                        {wp.connection_name}
                      </SelectItem>
                    ))
                  ) : (
                    <SelectItem value={"no data"}>No data</SelectItem>
                  )}
                </SelectContent>
              </Select>
              <Button size="sm" onClick={() => setOpenAddWP(true)}>
                <Plus className="size-4" />
              </Button>
            </div>
          </div>

          {selectedWp && (
            <div className="grid gap-2">
              <Label htmlFor="category">Category</Label>
              <Select
                value={String(category?.id || "")}
                onValueChange={(value) => {
                  if (value === "no data") return;
                  const wpCat = wpCategories.filter(
                    (cat) => cat.id === parseInt(value)
                  );
                  setCategory(wpCat[0] || null);
                }}
              >
                <SelectTrigger type="button" className="w-full">
                  <SelectValue
                    placeholder={
                      loadingWpData ? "Loading..." : "Select category"
                    }
                  />
                </SelectTrigger>
                <SelectContent>
                  {wpCategories.length > 0 ? (
                    wpCategories.map((cat) => (
                      <SelectItem key={cat.id} value={String(cat.id)}>
                        {cat.name}
                      </SelectItem>
                    ))
                  ) : (
                    <SelectItem value={"no data"}>No data</SelectItem>
                  )}
                </SelectContent>
              </Select>
            </div>
          )}

          {selectedWp && (
            <div className="grid gap-2">
              <Label htmlFor="author">Author</Label>
              <Select
                value={String(author?.id || "")}
                onValueChange={(value) => {
                  if (value === "no data") return;
                  const wpAuthor = wpUsers.filter(
                    (auth) => auth.id === parseInt(value)
                  );
                  setAuhtor(wpAuthor[0] || null);
                }}
              >
                <SelectTrigger type="button" className="w-full">
                  <SelectValue
                    placeholder={loadingWpData ? "Loading..." : "Select author"}
                  />
                </SelectTrigger>
                <SelectContent>
                  {wpUsers.length > 0 ? (
                    wpUsers.map((auth) => (
                      <SelectItem key={auth.id} value={String(auth.id)}>
                        {auth.name}
                      </SelectItem>
                    ))
                  ) : (
                    <SelectItem value={"no data"}>No data</SelectItem>
                  )}
                </SelectContent>
              </Select>
            </div>
          )} */}

            {/* <div className="grid gap-2">
            <Label htmlFor="hours">Hours between</Label>
            <Input
              type="number"
              id="hours"
              value={hoursBetween}
              onChange={(evt) => {
                const val = evt.target.value;
                if (parseInt(val) < 0) return;
                setHoursBetween(parseInt(val));
              }}
            />
          </div> */}

            <div className="grid gap-2 mt-4">
              <Label htmlFor="description">Description</Label>
              <div className="relative">
                <Textarea
                  required
                  id="subject"
                  value={description}
                  onChange={(evt) => {
                    setDescription(evt.target.value);
                  }}
                  rows={6}
                  className="resize-none"
                />
              </div>
            </div>

            <DialogFooter>
              <DialogClose asChild>
                <Button
                  disabled={submitting}
                  type="button"
                  size="sm"
                  variant="outline"
                >
                  Cancel
                </Button>
              </DialogClose>
              <Button disabled={submitting} type="submit" size="sm">
                {submitting && (
                  <RefreshCw className="size-4 animate-spin mr-2" />
                )}{" "}
                Save
              </Button>
            </DialogFooter>
          </form>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default AddBlogSettingDialog;
