import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import {
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbList,
  BreadcrumbPage,
  BreadcrumbSeparator,
} from "components/ui/breadcrumb";
import { Button } from "components/ui/button";

import AccountSelect from "../AccountSelect";
import { useAppState } from "context/AppContext";
import { DataTableGroupSchedules } from "./data";
import AddGroupScheduleDialog from "./AddGroupScheduleDialog";
import { GroupSchedule } from "types";
import apiClient from "api";

const GroupSchedules = () => {
  const { state } = useAppState();
  const { agency, client, account } = state.admin;

  const [data, setData] = useState<GroupSchedule[]>([]);
  const [loading, setLoading] = useState(false);
  const [openAddSetting, setOpenAddSetting] = useState(false);

  useEffect(() => {
    setData([]);
    if (!account) return;
    const timeout = setTimeout(fetchGroupSchedules, 200);
    return () => clearTimeout(timeout);
    // eslint-disable-next-line
  }, [account]);

  const fetchGroupSchedules = async () => {
    setLoading(true);
    try {
      const { data } = await apiClient.get(
        "/admin_creative_get_group_schedules",
        {
          headers: {
            request: JSON.stringify({ creative_id: account?.account_id }),
          },
        }
      );
      setData(data.message || []);
    } catch (error) {
      console.log("Error:", error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div>
      <Breadcrumb className="mb-4">
        <BreadcrumbList>
          <BreadcrumbItem>
            <Link to="/">Home</Link>
          </BreadcrumbItem>
          <BreadcrumbSeparator />
          <BreadcrumbItem>
            <Link to="/admin/agencies">{agency?.agency_name}</Link>
          </BreadcrumbItem>
          <BreadcrumbSeparator />
          <BreadcrumbItem>
            <Link to="/admin/clients">{client?.customer_name}</Link>
          </BreadcrumbItem>
          <BreadcrumbSeparator />
          <BreadcrumbItem>
            <Link to="/admin/accounts">{account?.account_name}</Link>
          </BreadcrumbItem>
          <BreadcrumbSeparator />
          <BreadcrumbItem>
            <BreadcrumbPage>Group schedules</BreadcrumbPage>
          </BreadcrumbItem>
        </BreadcrumbList>
      </Breadcrumb>

      <div className="space-y-2">
        <div className="flex justify-between items-center">
          <h3 className="scroll-m-20 text-2xl font-bold tracking-tight">
            Group schedules
          </h3>
          <AccountSelect />
        </div>
      </div>

      <div className="mt-8">
        <div className="flex justify-end">
          <Button size="sm" onClick={() => setOpenAddSetting(true)}>
            Add group schedule
          </Button>
        </div>
      </div>

      <DataTableGroupSchedules
        data={data}
        loading={loading}
        fetchData={fetchGroupSchedules}
      />

      <AddGroupScheduleDialog
        fetchData={fetchGroupSchedules}
        open={openAddSetting}
        onOpenChange={setOpenAddSetting}
      />
    </div>
  );
};

export default GroupSchedules;
