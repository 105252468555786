import { useEffect, useState } from "react";
import { RefreshCw } from "lucide-react";
import {
  Dialog,
  DialogClose,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from "components/ui/dialog";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "components/ui/select";

import { Label } from "components/ui/label";
import { Button } from "components/ui/button";
import { useAppState } from "context/AppContext";
import { Actor, GroupAnswerer, Poster } from "types";
import apiClient from "api";

type Props = {
  open: boolean;
  editData?: GroupAnswerer | null;
  fetchData: () => void;
  onOpenChange: (value: boolean) => void;
};

const AddGroupAnswererDialog = ({
  open,
  editData,
  fetchData,
  onOpenChange,
}: Props) => {
  const { state } = useAppState();
  const { account, group } = state.admin;

  const [actors, setActors] = useState<Actor[]>([]);
  const [posterTypes, setPosterTypes] = useState<Poster[]>([]);
  const [posterType, setPosterType] = useState<Poster | null>(null);
  const [answererList, setAnswererList] = useState<Actor[]>([]);
  const [answerer, setAnswerer] = useState<Actor | null>(null);
  const [submitting, setSubmitting] = useState(false);

  useEffect(() => {
    if (!editData) return;

    setPosterType(
      posterTypes.find((p) => p.content_type === editData.answerer_type) || null
    );
    // eslint-disable-next-line
  }, [editData, posterTypes]);

  useEffect(() => {
    if (!editData) return;

    const answerers = actors.filter(
      (item) =>
        item.actor_type === posterType?.content_type &&
        item.creative_id === account?.account_id
    );
    setAnswererList(answerers);
    // eslint-disable-next-line
  }, [editData, posterType]);

  useEffect(() => {
    if (!editData) return;
    setAnswerer(
      answererList.find(
        (a) =>
          a.actor_type === editData.answerer_type &&
          a.creative_id === account?.account_id
      ) || null
    );

    // eslint-disable-next-line
  }, [editData, answererList]);

  useEffect(() => {
    if (!open) {
      setPosterType(null);
      return;
    }

    fetchPosterTypes();
    getActorsList();

    // eslint-disable-next-line
  }, [open]);

  const getActorsList = async () => {
    try {
      const { data } = await apiClient.get("/admin_creative_get_actors");
      const list: Actor[] = data?.message || [];

      setActors(list ?? []);
    } catch (error) {
      console.log("error:", error);
    } finally {
    }
  };

  const fetchPosterTypes = async () => {
    try {
      const { data } = await apiClient.get("/admin_creative_get_poster_types");
      const content: Poster[] = data?.message || [];
      setPosterTypes(content);
    } catch (error) {
      console.error("Error:", error);
    } finally {
    }
  };

  const addGroupAnswerer = async (evt: React.FormEvent) => {
    evt.preventDefault();

    setSubmitting(true);
    if (Boolean(editData)) {
      try {
        await apiClient.post(
          "/admin_creative_update_group_answerer",
          {},
          {
            headers: {
              request: JSON.stringify({
                group_answerer_id: editData?.group_answerer_id,
                group_id: group?.group_id,
                answerer_type: posterType?.content_type,
                answerer_id: answerer?.actor_id,
              }),
            },
          }
        );
        onOpenChange(false);
        fetchData();
      } catch (error) {
        console.log("Error:", error);
      } finally {
        setSubmitting(false);
      }
    } else {
      try {
        await apiClient.post(
          "/admin_creative_add_group_answerer",
          {},
          {
            headers: {
              request: JSON.stringify({
                group_id: group?.group_id,
                answerer_type: posterType?.content_type,
                answerer_id: answerer?.actor_id,
              }),
            },
          }
        );
        onOpenChange(false);
        fetchData();
      } catch (error) {
        console.log("Error:", error);
      } finally {
        setSubmitting(false);
      }
    }
  };

  return (
    <>
      <Dialog open={open} onOpenChange={onOpenChange}>
        <DialogContent className="sm:max-w-[425px]">
          <DialogHeader>
            <DialogTitle>
              {!!editData ? "Edit" : "Add"} group answerer
            </DialogTitle>
          </DialogHeader>

          <form onSubmit={addGroupAnswerer} className="grid gap-4">
            <div className="grid gap-2">
              <Label htmlFor="poster-type">Poster type</Label>
              <div className="flex gap-2">
                <Select
                  required
                  value={String(posterType?.content_type)}
                  onValueChange={(value) => {
                    if (value === "no data") return;
                    const poster = posterTypes.filter(
                      (p) => p.content_type === parseInt(value)
                    );
                    setPosterType(poster[0] || null);
                    setAnswerer(null);
                    const answerers = actors.filter(
                      (item) =>
                        item.actor_type === poster[0]?.content_type &&
                        item.creative_id === account?.account_id
                    );
                    setAnswererList(answerers);
                  }}
                >
                  <SelectTrigger type="button" className="w-full">
                    <SelectValue placeholder={"Select poster type"} />
                  </SelectTrigger>
                  <SelectContent>
                    {posterTypes.length > 0 ? (
                      posterTypes.map((item) => (
                        <SelectItem
                          key={item.content_type}
                          value={String(item.content_type)}
                        >
                          {item.description}
                        </SelectItem>
                      ))
                    ) : (
                      <SelectItem value={"no data"}>No data</SelectItem>
                    )}
                  </SelectContent>
                </Select>
              </div>
            </div>

            <div className="grid gap-2">
              <Label htmlFor="answerer">Answerer</Label>
              <div className="flex gap-2">
                <Select
                  required
                  disabled={!Boolean(posterType)}
                  value={String(answerer?.actor_id)}
                  onValueChange={(value) => {
                    if (value === "no data") return;
                    const _answerer = answererList.filter(
                      (d) => d.actor_id === parseInt(value)
                    );
                    setAnswerer(_answerer[0] || null);
                  }}
                >
                  <SelectTrigger type="button" className="w-full">
                    <SelectValue placeholder={"Select answerer"} />
                  </SelectTrigger>
                  <SelectContent>
                    {answererList.length > 0 ? (
                      answererList.map((item) => (
                        <SelectItem
                          key={item.actor_id}
                          value={String(item.actor_id)}
                        >
                          {item.actor_name}
                        </SelectItem>
                      ))
                    ) : (
                      <SelectItem value={"no data"}>No data</SelectItem>
                    )}
                  </SelectContent>
                </Select>
              </div>
            </div>

            <DialogFooter>
              <DialogClose asChild>
                <Button
                  disabled={submitting}
                  type="button"
                  size="sm"
                  variant="outline"
                >
                  Cancel
                </Button>
              </DialogClose>
              <Button disabled={submitting} type="submit" size="sm">
                {submitting && (
                  <RefreshCw className="size-4 animate-spin mr-2" />
                )}{" "}
                Save
              </Button>
            </DialogFooter>
          </form>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default AddGroupAnswererDialog;
