import React, { useEffect, useState } from "react";
import { RefreshCw } from "lucide-react";
import {
  Dialog,
  DialogClose,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from "components/ui/dialog";

import { Label } from "components/ui/label";
import { Button } from "components/ui/button";
import { Textarea } from "components/ui/textarea";
import { useToast } from "components/ui/use-toast";
import { Input } from "components/ui/input";
import { SocialChannelType } from "types";
import apiClient from "api";

type Props = {
  open: boolean;
  editData?: SocialChannelType | null;
  fetchData: () => void;
  onOpenChange: (value: boolean) => void;
};

const AddSocialChannelDialog = ({
  open,
  editData,
  fetchData,
  onOpenChange,
}: Props) => {
  const { toast } = useToast();
  const [submitting, setSubmitting] = useState(false);
  const [channelId, setChannelId] = useState(0);
  const [description, setDescription] = useState("");

  useEffect(() => {
    if (!editData) return;
    setChannelId(editData.channel_id);
    setDescription(editData.description);
  }, [editData]);

  const addSocialChannel = async (evt: React.FormEvent) => {
    evt.preventDefault();
    setSubmitting(true);
    if (Boolean(editData)) {
      try {
        await apiClient.post(
          "/admin_creative_update_social_channel",
          {},
          {
            headers: {
              request: JSON.stringify({
                channel_id: channelId,
                description,
              }),
            },
          }
        );
        onOpenChange(false);
        fetchData();
      } catch (error) {
        console.log("Error:", error);
      } finally {
        setSubmitting(false);
      }
    } else {
      try {
        const { data } = await apiClient.post(
          "/admin_creative_add_social_channel",
          {},
          {
            headers: {
              request: JSON.stringify({
                channel_id: channelId,
                description,
              }),
            },
          }
        );

        if (data?.message[0].result === -1) {
          toast({
            title: "Duplicate channel",
            description: "Social channel already exists.",
          });
        } else {
          onOpenChange(false);
          fetchData();
        }
      } catch (error) {
        console.log("Error:", error);
      } finally {
        setSubmitting(false);
      }
    }
  };

  return (
    <>
      <Dialog open={open} onOpenChange={onOpenChange}>
        <DialogContent className="sm:max-w-[425px]">
          <DialogHeader>
            <DialogTitle>
              {!!editData ? "Edit" : "Add"} content type
            </DialogTitle>
          </DialogHeader>

          <form className="grid gap-4" onSubmit={addSocialChannel}>
            <div className="grid gap-2">
              <Label htmlFor="channel-id">Channel ID</Label>
              <div className="relative">
                <Input
                  id="channel-id"
                  type="number"
                  required
                  readOnly={Boolean(editData)}
                  value={channelId}
                  onChange={(evt) => {
                    const val = parseInt(evt.target.value);
                    if (val < 0) return;

                    setChannelId(val);
                  }}
                />
              </div>
            </div>
            <div className="grid gap-2">
              <Label htmlFor="description">Description</Label>
              <div className="relative">
                <Textarea
                  required
                  id="description"
                  value={description}
                  onChange={(evt) => {
                    setDescription(evt.target.value);
                  }}
                  rows={6}
                  className="resize-none"
                />
              </div>
            </div>

            <DialogFooter>
              <DialogClose asChild>
                <Button
                  disabled={submitting}
                  type="button"
                  size="sm"
                  variant="outline"
                >
                  Cancel
                </Button>
              </DialogClose>
              <Button disabled={submitting} type="submit" size="sm">
                {submitting && (
                  <RefreshCw className="size-4 animate-spin mr-2" />
                )}{" "}
                Save
              </Button>
            </DialogFooter>
          </form>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default AddSocialChannelDialog;
