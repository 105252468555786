import { Fragment } from "react";
import { Link } from "react-router-dom";
import { HomeIcon, SlashIcon } from "@radix-ui/react-icons";
import {
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  BreadcrumbList,
  BreadcrumbPage,
  BreadcrumbSeparator,
} from "components/ui/breadcrumb";

type BreadcrumbsProps = {
  routes: { title: string; path: string }[];
  home?: { title: string; path: string };
};

const Breadcrumbs = ({ routes, home }: BreadcrumbsProps) => {
  return (
    <Breadcrumb>
      <BreadcrumbList>
        <BreadcrumbItem>
          <HomeIcon className="w-4 h-4" />
          <BreadcrumbLink asChild>
            <Link to={home?.path ?? "/"}>{home?.title ?? "Home"}</Link>
          </BreadcrumbLink>
        </BreadcrumbItem>

        {routes.map((route, index) =>
          routes.length === 1 ? (
            <Fragment key={route.title}>
              <BreadcrumbSeparator>
                <SlashIcon />
              </BreadcrumbSeparator>
              <BreadcrumbItem key={route.title}>
                <BreadcrumbPage>{route.title}</BreadcrumbPage>
              </BreadcrumbItem>
            </Fragment>
          ) : (
            <Fragment key={route.title}>
              <BreadcrumbSeparator>
                <SlashIcon />
              </BreadcrumbSeparator>
              <BreadcrumbItem>
                {index + 1 === routes.length ? (
                  <BreadcrumbPage>{route.title}</BreadcrumbPage>
                ) : (
                  <BreadcrumbLink asChild>
                    <Link to={route.path}>{route.title}</Link>
                  </BreadcrumbLink>
                )}
              </BreadcrumbItem>
            </Fragment>
          )
        )}
      </BreadcrumbList>
    </Breadcrumb>
  );
};

export default Breadcrumbs;
