import { memo, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { columns } from "components/Tables/schedules/columns";
import { DataTable } from "components/Tables/schedules/data-table";
// import { CalendarDateRangePicker } from "components/Tables/schedules/date-range-picker";
import { useAppState } from "context/AppContext";
import { Schedule } from "types";
import apiClient from "api";
import { Button } from "components/ui/button";
import { Plus } from "lucide-react";

const Schedules = () => {
  const { state, dispatch } = useAppState();
  const navigate = useNavigate();
  const { account_token, refresh } = state.user;

  const [isLoading, setIsLoading] = useState(false);
  const [schedules, setSchedules] = useState<Schedule[]>([]);

  useEffect(() => {
    if (refresh !== "schedules") return;
    const timeout = setTimeout(fetchSchedules, 100);
    return () => clearTimeout(timeout);
    // eslint-disable-next-line
  }, [refresh]);

  useEffect(() => {
    if (!account_token) return;
    const timeout = setTimeout(fetchSchedules, 100);
    return () => clearTimeout(timeout);
    // eslint-disable-next-line
  }, [account_token]);

  const fetchSchedules = async () => {
    setIsLoading(true);
    try {
      const { data }: { data: { message: Schedule[] } } = await apiClient.get(
        "/get_scheduled_linkage_dashboard",
        {
          headers: {
            token: account_token,
          },
        }
      );
      const allSchedules = data?.message || [];
      const undeletedSchedules =
        allSchedules.length > 0
          ? allSchedules.filter((s) => !s.deleted || s.deleted === 0)
          : [];

      setSchedules(undeletedSchedules);
      setIsLoading(false);
      if (refresh === "schedules")
        dispatch({ type: "SET_REFRESH", payload: "" });
    } catch (error) {
      setIsLoading(false);
    }
  };
  return (
    <div className="flex flex-col space-y-8 pb-4 lg:p-8">
      <div className="flex items-center justify-between space-y-2">
        <div>
          <h2 className="text-2xl font-bold tracking-tight">Schedules</h2>
          <p className="text-muted-foreground">
            Here&apos;s a list of your schedules for this month.
          </p>
        </div>
        <div className="flex items-center space-x-2">
          <Button
            size="sm"
            variant="default"
            onClick={() => navigate("/schedules/setup")}
          >
            <Plus className="size-4 mr-2" /> Add schedules
          </Button>
        </div>
      </div>
      <DataTable data={schedules} isLoading={isLoading} columns={columns} />
    </div>
  );
};

export default memo(Schedules);
