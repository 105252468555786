import { useEffect, useState } from "react";
import { Card, CardContent, CardHeader, CardTitle } from "components/ui/card";
import Breadcrumbs from "components/Shared/Breadcrumbs";
import NumberCounter from "components/Shared/NumberCounter";
import { Skeleton } from "components/ui/skeleton";

import { useAppState } from "context/AppContext";
import { DataTable } from "../PostsTable/data-table";
import { columns } from "../PostsTable/columns";
import { PostsChart } from "../PostsChart";
import { OverviewPost } from "types";
import apiClient from "api";

const WordpressOverview = () => {
  const { state } = useAppState();
  const { agency, account, client } = state.user;

  const [loading, setLoading] = useState(false);
  const [clickThru, setClickThru] = useState(0);
  const [postCount, setPostCount] = useState(0);
  const [posts, setPosts] = useState<OverviewPost[]>([]);
  const [chartsData, setChartsData] = useState<
    { id: number; date: string; clickThruRate: number }[]
  >([]);

  useEffect(() => {
    setLoading(true);
    const getVolumes = async () => {
      const request: { [key: string]: number } = {
        id: 0,
        level: 1,
      };

      if (agency) {
        request["id"] = agency.agency_id;
        request["level"] = 2;
      }
      if (client) {
        request["id"] = client.customer_id;
        request["level"] = 3;
      }
      if (account) {
        request["id"] = account.account_id;
        request["level"] = 4;
      }

      try {
        const { data } = await apiClient.get("creative_get_post_list", {
          headers: {
            request: JSON.stringify({ ...request, social_id: 4 }),
          },
        });

        const {
          data: stats,
        }: {
          data: {
            message: {
              facebook_quantity: number;
              linkedin_quantity: number;
              post_quantity: number;
              x_quantity: number;
            }[];
          };
        } = await apiClient.get("creative_get_post_stats", {
          headers: {
            request: JSON.stringify(request),
          },
        });

        setPosts(data?.message || []);
        setPostCount(stats.message[0]?.post_quantity || 0);
        setClickThru(calculateTotalClickThruRate(data?.message));

        setLoading(false);
      } catch (error) {
        setLoading(false);
        console.log(error);
      }
    };

    getVolumes();
  }, [agency, account, client]);

  function calculateTotalClickThruRate(data: OverviewPost[]): number {
    const totalClickThruRate = data.reduce(
      (sum, item) => sum + item.click_thru_rate,
      0
    );
    return totalClickThruRate;
  }

  return (
    <div>
      <Breadcrumbs
        home={{ title: "Overview", path: "/" }}
        routes={[{ title: "Website overview", path: "" }]}
      />

      <div className="mt-4">
        <Card className="rounded-md group md:max-w-md w-full">
          <CardHeader className="flex flex-row justify-between space-y-0 pb-2">
            <CardTitle className="font-medium text-muted-foreground">
              Wordpress posts
            </CardTitle>
            <span>
              <svg
                className="w-5 h-5 text-gray-800 dark:text-white"
                aria-hidden="true"
                fill="currentColor"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
              >
                <g id="SVGRepo_bgCarrier" strokeWidth="0"></g>
                <g
                  id="SVGRepo_tracerCarrier"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                ></g>
                <g id="SVGRepo_iconCarrier">
                  <path d="M19.891 7.788a8.966 8.966 0 0 1 1.099 4.313 8.986 8.986 0 0 1-4.47 7.771l2.746-7.939c.513-1.282.684-2.309.684-3.219a7.165 7.165 0 0 0-.059-.926m-6.651.087a14.14 14.14 0 0 0 1.026-.088c.485-.063.428-.775-.056-.749 0 0-1.463.112-2.4.112-.887 0-2.375-.125-2.375-.125-.487-.024-.55.713-.061.738 0 0 .449.052.938.075l1.399 3.838-1.975 5.899-3.274-9.724a17.006 17.006 0 0 0 1.028-.083c.487-.063.43-.775-.055-.747 0 0-1.455.115-2.395.115-.167 0-.365-.007-.575-.013C6.093 4.726 8.862 3.113 12 3.113c2.341 0 4.471.894 6.071 2.36-.038-.002-.076-.008-.117-.008-.883 0-1.51.77-1.51 1.596 0 .741.427 1.369.883 2.108.343.601.742 1.37.742 2.481 0 .763-.295 1.662-.685 2.899l-.896 2.987-3.25-9.675.002.014zM12 21.087a8.983 8.983 0 0 1-2.54-.364l2.697-7.838 2.763 7.572c.021.044.042.085.065.124a9.016 9.016 0 0 1-2.985.508m-8.99-8.988a8.94 8.94 0 0 1 .778-3.658l4.287 11.749a8.993 8.993 0 0 1-5.065-8.091m8.99-10c-5.513 0-10 4.487-10 10s4.487 10 10 10 10-4.487 10-10-4.487-10-10-10"></path>
                </g>
              </svg>
            </span>
          </CardHeader>
          <CardContent>
            <div className="text-xl font-bold">
              {loading ? (
                <Skeleton className="h-8 w-[100px]" />
              ) : (
                <>
                  Total posts{" "}
                  <NumberCounter
                    className="inline-block"
                    targetNumber={postCount}
                  />
                  {" | "} Click-throughs{" "}
                  <NumberCounter
                    className="inline-block"
                    targetNumber={clickThru}
                  />
                </>
              )}
            </div>
          </CardContent>
        </Card>

        <div className="mt-8 grid lg:grid-cols-2 gap-4">
          <DataTable
            data={posts}
            isLoading={loading}
            columns={columns}
            setChartsData={setChartsData}
          />

          <PostsChart data={chartsData} isLoading={loading} />
        </div>
      </div>
    </div>
  );
};

export default WordpressOverview;
