import { RouteObject } from "react-router-dom";

import Posts from "./private/Posts";
import Container from "Layout/Container";
import Overview from "./private/Overview";
import Schedules from "./private/Schedules";
import ProtectedRoute from "Layout/RootLayout";
import Connections from "./private/Connections";
import Wordpress from "./private/Wordpress";
import {
  Post,
  Login,
  Agency,
  Client,
  Account,
  SetupPost,
  SetupSchedule,
  AdminDashbaord,
  SetupConnection,
  AccountSummary,
  AccountCampaigns,
  AccountConnections,
  AccountSocialChannels,
  AccountPosts,
  AccountWordpress,
  AccountClickfarm,
  AccountBlogPosts,
  AccountAvatars,
  ContentTypes,
  AccountWordpressSchedules,
  AvatarSchedules,
  PosterTypes,
  GroupSchedules,
  AccountGroups,
  AccountPages,
  AccountWebsites,
  AccountTwitterAPIs,
  AccountPostStatuses,
  WordpressOverview,
  FacebookOverview,
  LinkedinOverview,
  TwitterOverview,
  ContentSources,
  PostView,
  AccountHintSubjects,
  AccountQuestionSubjects,
  AccountHintSchedules,
  AccountQuestionSchedules,
  TargetTypes,
  AccountGroupAnswerers,
  AccountRedirectBuilder,
} from "routes";

// Define and export the router configuration
export const routes: RouteObject[] = [
  {
    path: "/",
    element: <ProtectedRoute />,
    children: [
      {
        path: "/",
        element: (
          <Container>
            <Overview />
          </Container>
        ),
      },
      {
        path: "/overview",
        element: (
          <Container>
            <Overview />
          </Container>
        ),
      },
      {
        path: "/overview/website",
        element: (
          <Container>
            <WordpressOverview />
          </Container>
        ),
      },
      {
        path: "/overview/facebook",
        element: (
          <Container>
            <FacebookOverview />
          </Container>
        ),
      },
      {
        path: "/overview/linkedin",
        element: (
          <Container>
            <LinkedinOverview />
          </Container>
        ),
      },
      {
        path: "/overview/twitter",
        element: (
          <Container>
            <TwitterOverview />
          </Container>
        ),
      },
      {
        path: "/overview/:social/post/:id",
        element: (
          <Container>
            <PostView />
          </Container>
        ),
      },
      {
        path: "/schedules",
        element: (
          <Container container={true}>
            <Schedules />
          </Container>
        ),
      },
      {
        path: "/schedules/setup",
        element: (
          <Container navigation={false} header={false} fullScreen>
            <SetupSchedule />
          </Container>
        ),
      },
      {
        path: "/connections",
        element: (
          <Container container={true}>
            <Connections />
          </Container>
        ),
      },
      {
        path: "/websites",
        element: (
          <Container container={true}>
            <Wordpress />
          </Container>
        ),
      },
      {
        path: "/connections/setup",
        element: (
          <Container navigation={false} header={false} fullScreen>
            <SetupConnection />
          </Container>
        ),
      },
      {
        path: "/posts",
        element: (
          <Container container={true}>
            <Posts />
          </Container>
        ),
      },
      {
        path: "/posts/setup",
        element: (
          <Container navigation={false} header={false} fullScreen>
            <SetupPost />
          </Container>
        ),
      },
      {
        path: "/posts/:id",
        element: (
          <Container navigation={false} header={true}>
            <Post />
          </Container>
        ),
      },
      {
        path: "/admin",
        element: (
          <Container navigation={false} header={true} fullScreen>
            <AdminDashbaord />
          </Container>
        ),
        children: [
          {
            path: "agencies",
            element: <Agency />,
          },
          {
            path: "clients",
            element: <Client />,
          },
          {
            path: "accounts",
            element: <Account />,
          },
          {
            path: "account/summary",
            element: <AccountSummary />,
          },
          {
            path: "account/redirect-builder",
            element: <AccountRedirectBuilder />,
          },
          {
            path: "account/campaigns",
            element: <AccountCampaigns />,
          },
          {
            path: "account/connections",
            element: <AccountConnections />,
          },
          {
            path: "account/social-channels",
            element: <AccountSocialChannels />,
          },
          {
            path: "account/newsportal",
            element: <AccountWordpress />,
          },
          {
            path: "account/long-form",
            element: <AccountBlogPosts />,
          },
          {
            path: "account/websites",
            element: <AccountWebsites />,
          },
          {
            path: "account/avatars",
            element: <AccountAvatars />,
          },
          {
            path: "account/group-answerers",
            element: <AccountGroupAnswerers />,
          },
          {
            path: "account/groups",
            element: <AccountGroups />,
          },
          {
            path: "account/pages",
            element: <AccountPages />,
          },
          {
            path: "account/content-types",
            element: <ContentTypes />,
          },
          {
            path: "account/target-types",
            element: <TargetTypes />,
          },
          {
            path: "account/content-sources",
            element: <ContentSources />,
          },
          {
            path: "account/poster-types",
            element: <PosterTypes />,
          },
          {
            path: "account/wordpress-schedules",
            element: <AccountWordpressSchedules />,
          },
          {
            path: "account/avatar-schedules",
            element: <AvatarSchedules />,
          },
          {
            path: "account/group-schedules",
            element: <GroupSchedules />,
          },
          {
            path: "account/posts",
            element: <AccountPosts />,
          },
          {
            path: "account/twitter-apis",
            element: <AccountTwitterAPIs />,
          },
          {
            path: "account/post-statuses",
            element: <AccountPostStatuses />,
          },
          {
            path: "account/hint-subjects",
            element: <AccountHintSubjects />,
          },
          {
            path: "account/question-subjects",
            element: <AccountQuestionSubjects />,
          },
          {
            path: "account/hint-schedules",
            element: <AccountHintSchedules />,
          },
          {
            path: "account/question-schedules",
            element: <AccountQuestionSchedules />,
          },
          {
            path: "account/clickfarm",
            element: <AccountClickfarm />,
          },
        ],
      },
      // Add other child routes here
    ],
  },
  { path: "/login", element: <Login /> },
];
