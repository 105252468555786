import { useState } from "react";
import { DotsHorizontalIcon } from "@radix-ui/react-icons";
import { Row } from "@tanstack/react-table";

import { Button } from "components/ui/button";
import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
} from "components/ui/alert-dialog";
import {
  Dialog,
  DialogClose,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from "components/ui/dialog";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from "components/ui/dropdown-menu";

import { Schedule } from "types";
import apiClient from "api";
import { useToast } from "components/ui/use-toast";
import { useAppState } from "context/AppContext";
import { Label } from "components/ui/label";
import { Input } from "components/ui/input";

interface DataTableRowActionsProps<TData> {
  row: Row<TData>;
}

export function DataTableRowActions({
  row,
}: DataTableRowActionsProps<Schedule>) {
  const schedule = row.original;
  const { state, dispatch } = useAppState();
  const { account_token } = state.user;
  const { toast } = useToast();

  const [name, setName] = useState(schedule.schedule_name || "");
  const [openEdit, setOpenEdit] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const [openDeleteConfirm, setOpenDeleteConfirm] = useState(false);

  const deleteSchedule = async () => {
    setIsDeleting(isDeleting);
    toast({
      description: "Deleting schedule...",
    });
    try {
      await apiClient.delete("/delete_scheduled_linkage", {
        headers: {
          token: account_token,
          request: JSON.stringify({
            schedule_id: schedule.schedule_id,
          }),
        },
      });

      toast({
        description: "Delete successful!",
      });
      setIsDeleting(false);
      dispatch({ type: "SET_REFRESH", payload: "schedules" });
    } catch (error) {
      setIsDeleting(false);
    }
  };

  const updateSchedule = async () => {
    toast({
      description: "Updating schedule name...",
    });

    try {
      await apiClient.post(
        "/update_scheduled_linkage",
        {},
        {
          headers: {
            token: account_token,
            request: JSON.stringify({
              content_source: 1,
              schedule_name: name,
              channel_id: schedule?.channel_id,
              schedule_id: schedule?.schedule_id,
              content_id: schedule?.content_id,
            }),
          },
        }
      );
      dispatch({ type: "SET_REFRESH", payload: "schedules" });
      toast({
        description: "Name updated successfully.",
      });
    } catch (error) {
      toast({
        description: "Name updated failed.",
      });
    }
  };

  return (
    <>
      <DropdownMenu>
        <DropdownMenuTrigger asChild>
          <Button
            variant="ghost"
            className="flex h-8 w-8 p-0 data-[state=open]:bg-muted"
          >
            <DotsHorizontalIcon className="h-4 w-4" />
            <span className="sr-only">Open menu</span>
          </Button>
        </DropdownMenuTrigger>
        <DropdownMenuContent align="end" className="w-[160px]">
          <DropdownMenuLabel>Actions</DropdownMenuLabel>
          <DropdownMenuItem onClick={() => setOpenEdit(true)}>
            {/* <Pencil className="mr-2 h-4 w-4" /> */}
            Edit name
          </DropdownMenuItem>

          <DropdownMenuSeparator />
          <DropdownMenuItem
            onClick={() => setOpenDeleteConfirm(true)}
            className="text-red-600"
          >
            {/* <Trash className="mr-2 h-4 w-4" /> */}
            Delete
          </DropdownMenuItem>
        </DropdownMenuContent>
      </DropdownMenu>

      <Dialog open={openEdit} onOpenChange={setOpenEdit}>
        <DialogContent className="sm:max-w-md">
          <DialogHeader>
            <DialogTitle>Edit connection name</DialogTitle>
          </DialogHeader>
          <div className="flex items-center space-x-2">
            <div className="grid flex-1 gap-2">
              <Label htmlFor="link" className="sr-only">
                Link
              </Label>
              <Input
                id="link"
                value={name}
                onChange={(evt) => setName(evt.target.value)}
              />
            </div>
          </div>
          <DialogFooter className="">
            <DialogClose asChild>
              <Button
                onClick={updateSchedule}
                disabled={row.original.schedule_name === name || !name}
              >
                Save
              </Button>
            </DialogClose>
            <DialogClose asChild>
              <Button type="button" variant="secondary">
                Close
              </Button>
            </DialogClose>
          </DialogFooter>
        </DialogContent>
      </Dialog>

      <AlertDialog open={openDeleteConfirm} onOpenChange={setOpenDeleteConfirm}>
        <AlertDialogContent>
          <AlertDialogHeader>
            <AlertDialogTitle>Are you sure?</AlertDialogTitle>
            <AlertDialogDescription>
              This action cannot be undone. This will permanently delete your
              schedules.
            </AlertDialogDescription>
          </AlertDialogHeader>
          <AlertDialogFooter>
            <AlertDialogCancel>Cancel</AlertDialogCancel>
            <AlertDialogAction onClick={deleteSchedule}>
              Confirm
            </AlertDialogAction>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialog>
    </>
  );
}
