import * as React from "react";
import {
  ColumnDef,
  ColumnFiltersState,
  Row,
  SortingState,
  VisibilityState,
  flexRender,
  getCoreRowModel,
  getFilteredRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  useReactTable,
} from "@tanstack/react-table";
import { MoreHorizontal } from "lucide-react";
import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
} from "components/ui/alert-dialog";
import { Button } from "components/ui/button";
// import { Checkbox } from "components/ui/checkbox";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from "components/ui/dropdown-menu";
import { Input } from "components/ui/input";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "components/ui/table";
import { AccountList } from "types";
import { useAppState } from "context/AppContext";
import { useNavigate } from "react-router-dom";
import { useToast } from "components/ui/use-toast";
import { DataTableColumnHeader } from "components/Shared/AdminTableHeader";
import apiClient from "api";
import { AdminTablePagination } from "components/Shared/AdminTablePagination";

type Props = {
  data: AccountList[];
  loading: boolean;
  fetchData: () => void;
  openAddAccount: () => void;
  openEditAccount: (account: AccountList) => void;
};

interface DataTableRowActionsProps<TData> {
  row: Row<TData>;
  fetchData: () => void;
  openEditAccount: (account: AccountList) => void;
}

export function DataTableAccount({
  data,
  loading,
  fetchData,
  openAddAccount,
  openEditAccount,
}: Props) {
  const { state, dispatch } = useAppState();
  const { account } = state.admin;
  const navigate = useNavigate();
  const [sorting, setSorting] = React.useState<SortingState>([]);
  const [columnFilters, setColumnFilters] = React.useState<ColumnFiltersState>(
    []
  );
  const [columnVisibility, setColumnVisibility] =
    React.useState<VisibilityState>({});
  const [rowSelection, setRowSelection] = React.useState({});

  const columns: ColumnDef<AccountList>[] = [
    // {
    //   id: "select",
    //   header: ({ table }) => (
    //     <Checkbox
    //       checked={
    //         table.getIsAllPageRowsSelected() ||
    //         (table.getIsSomePageRowsSelected() && "indeterminate")
    //       }
    //       onCheckedChange={(value) => table.toggleAllPageRowsSelected(!!value)}
    //       aria-label="Select all"
    //     />
    //   ),
    //   cell: ({ row }) => (
    //     <Checkbox
    //       checked={row.getIsSelected()}
    //       onCheckedChange={(value) => row.toggleSelected(!!value)}
    //       aria-label="Select row"
    //     />
    //   ),
    //   enableSorting: false,
    //   enableHiding: false,
    // },
    {
      accessorKey: "account_id",
      header: ({ column }) => (
        <DataTableColumnHeader column={column} title="ID" />
      ),
      cell: ({ row }) => <div>{row.getValue("account_id")}</div>,
    },
    {
      accessorKey: "account_name",
      header: ({ column }) => (
        <DataTableColumnHeader column={column} title="Account name" />
      ),
      cell: ({ row }) => (
        <div className="hover:underline underline-offset-1 cursor-pointer">
          {row.getValue("account_name")}
        </div>
      ),
    },
    {
      id: "actions",
      enableHiding: false,
      cell: ({ row }) => (
        <RowAction
          fetchData={fetchData}
          row={row}
          openEditAccount={openEditAccount}
        />
      ),
    },
  ];

  const table = useReactTable({
    data,
    columns,
    onSortingChange: setSorting,
    onColumnFiltersChange: setColumnFilters,
    getCoreRowModel: getCoreRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    onColumnVisibilityChange: setColumnVisibility,
    onRowSelectionChange: setRowSelection,
    state: {
      sorting,
      columnFilters,
      columnVisibility,
      rowSelection,
    },
  });

  const resetSelection = (value: string) => {
    if (account?.account_name === value) return;
    dispatch({
      type: "SET_ADMIN_CAMPAIGN",
      payload: undefined,
    });
  };

  return (
    <div className="w-full">
      <div className="flex items-center py-4">
        <Input
          placeholder="Filter accounts..."
          value={
            (table.getColumn("account_name")?.getFilterValue() as string) ?? ""
          }
          onChange={(event) =>
            table.getColumn("account_name")?.setFilterValue(event.target.value)
          }
          className="max-w-sm"
        />
        <Button size="sm" className="ml-auto" onClick={openAddAccount}>
          Add account
        </Button>
      </div>
      <div className="rounded-md border">
        <Table>
          <TableHeader>
            {table.getHeaderGroups().map((headerGroup) => (
              <TableRow key={headerGroup.id}>
                {headerGroup.headers.map((header) => {
                  return (
                    <TableHead key={header.id}>
                      {header.isPlaceholder
                        ? null
                        : flexRender(
                            header.column.columnDef.header,
                            header.getContext()
                          )}
                    </TableHead>
                  );
                })}
              </TableRow>
            ))}
          </TableHeader>
          <TableBody>
            {table.getRowModel().rows?.length ? (
              table.getRowModel().rows.map((row) => (
                <TableRow
                  key={row.id}
                  data-state={row.getIsSelected() && "selected"}
                >
                  {row.getVisibleCells().map((cell) => (
                    <TableCell
                      className="py-0.5 text-xs"
                      key={cell.id}
                      onClick={() => {
                        if (cell.column.id === "account_name") {
                          dispatch({
                            type: "SET_ADMIN_ACCOUNT",
                            payload: row.original,
                          });
                          navigate("/admin/account/summary");
                          resetSelection(cell.getValue() as string);
                        }
                      }}
                    >
                      {flexRender(
                        cell.column.columnDef.cell,
                        cell.getContext()
                      )}
                    </TableCell>
                  ))}
                </TableRow>
              ))
            ) : (
              <TableRow>
                <TableCell
                  colSpan={columns.length}
                  className="h-24 text-center"
                >
                  {loading ? (
                    <div className="min-h-20 flex items-center justify-center">
                      <div className="flex flex-col justify-center items-center">
                        <div className="size-8 border-2 border-t-2 border-muted border-t-primary rounded-full animate-spin"></div>
                        <span className="text-sm text-center mt-4 text-primary">
                          Loading accounts...
                        </span>
                      </div>
                    </div>
                  ) : (
                    data.length === 0 && "No results."
                  )}
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </div>
      <AdminTablePagination table={table} />
    </div>
  );
}

const RowAction = ({
  row,
  fetchData,
  openEditAccount,
}: DataTableRowActionsProps<AccountList>) => {
  const { toast } = useToast();
  const [openAlert, setOpenAlert] = React.useState(false);
  const [isDeleting, setIsDeleting] = React.useState(false);

  const account = row.original;

  const deleteAccount = async () => {
    setIsDeleting(true);
    toast({
      description: "Deleting account...",
      variant: "destructive",
    });

    try {
      await apiClient.delete("/admin_creative_delete_account", {
        headers: {
          request: JSON.stringify({
            creative_account_id: account.account_id,
          }),
        },
      });
      fetchData();
      setIsDeleting(false);
      toast({
        description: "Account deleted successfully.",
        variant: "destructive",
      });
    } catch (error) {
      setIsDeleting(false);
      toast({
        description: "Account deletion failed.",
        variant: "destructive",
      });
    }
  };

  return (
    <>
      <DropdownMenu>
        <DropdownMenuTrigger asChild>
          <Button variant="ghost" className="h-6 w-6 p-0">
            <span className="sr-only">Open menu</span>
            <MoreHorizontal className="h-3 w-3" />
          </Button>
        </DropdownMenuTrigger>
        <DropdownMenuContent align="end">
          <DropdownMenuLabel>Actions</DropdownMenuLabel>
          <DropdownMenuItem
            onClick={() => {
              openEditAccount(account);
            }}
          >
            Edit account name
          </DropdownMenuItem>
          <DropdownMenuSeparator />
          <DropdownMenuItem
            disabled={isDeleting}
            onClick={() => setOpenAlert(true)}
            className="text-red-600"
          >
            Delete
          </DropdownMenuItem>
        </DropdownMenuContent>
      </DropdownMenu>

      <AlertDialog open={openAlert} onOpenChange={setOpenAlert}>
        <AlertDialogContent>
          <AlertDialogHeader>
            <AlertDialogTitle>Are you sure?</AlertDialogTitle>
            <AlertDialogDescription>
              This action cannot be undone. This will permanently delete your
              account.
            </AlertDialogDescription>
          </AlertDialogHeader>
          <AlertDialogFooter>
            <AlertDialogCancel>Cancel</AlertDialogCancel>
            <AlertDialogAction onClick={deleteAccount}>
              Confirm
            </AlertDialogAction>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialog>
    </>
  );
};
