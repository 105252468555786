import { useEffect, useState } from "react";
import { RefreshCw } from "lucide-react";
import {
  Dialog,
  DialogClose,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from "components/ui/dialog";
import { Label } from "components/ui/label";
import { Button } from "components/ui/button";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "components/ui/select";
import { Input } from "components/ui/input";
import { useAppState } from "context/AppContext";
import { HintSchedule, Target, Poster, Actor } from "types";
import apiClient from "api";

type Props = {
  open: boolean;
  editData?: HintSchedule | null;
  fetchData: () => void;
  onOpenChange: (value: boolean) => void;
};

const AddHintScheduleDialog = ({
  open,
  editData,
  fetchData,
  onOpenChange,
}: Props) => {
  const { state } = useAppState();
  const { account, hint_subject } = state.admin;
  const [targetTypes, setTargetTypes] = useState<Target[]>([]);
  const [targetType, setTargetType] = useState<Target | null>(null);
  const [posterTypes, setPosterTypes] = useState<Poster[]>([]);
  const [posterType, setPosterType] = useState<Poster | null>(null);
  const [actors, setActors] = useState<Actor[]>([]);
  const [posterList, setPosterList] = useState<Actor[]>([]);
  const [targetList, setTargetList] = useState<Actor[]>([]);
  const [poster, setPoster] = useState<Actor | null>(null);
  const [target, setTarget] = useState<Actor | null>(null);
  const [submitting, setSubmitting] = useState(false);
  const [hoursBetween, setHoursBetween] = useState(0);

  useEffect(() => {
    if (!editData) return;
    setHoursBetween(editData.hours_between);
    setTargetType(
      targetTypes.find((c) => c.target_id === editData.target_type) || null
    );
    setPosterType(
      posterTypes.find((p) => p.content_type === editData.poster_type) || null
    );

    // eslint-disable-next-line
  }, [editData, posterTypes, targetTypes, actors]);

  useEffect(() => {
    if (!editData) return;
    const targets = actors.filter(
      (item) =>
        item.actor_type === targetType?.target_id &&
        item.creative_id === account?.account_id
    );
    setTargetList(targets);
    const posters = actors.filter(
      (item) =>
        item.actor_type === posterType?.content_type &&
        item.creative_id === account?.account_id
    );
    setPosterList(posters);
    // eslint-disable-next-line
  }, [ posterType, targetType]);

  useEffect(() => {
    if (!editData) return;
    setTarget(
      targetList.find(
        (a) =>
          a.actor_type === editData.target_type &&
          a.creative_id === account?.account_id
      ) || null
    );
    setPoster(
      posterList.find(
        (a) =>
          a.actor_type === editData.poster_type &&
          a.creative_id === account?.account_id
      ) || null
    );
    // eslint-disable-next-line
  }, [ posterList, targetList]);

  useEffect(() => {
    if (!open) {
      setHoursBetween(0);
      setTargetType(null);
      setTarget(null);
      setPoster(null);
      setPosterType(null);
      return;
    }

    fetchPosterTypes();
    fetchTargetTypes();
    getActorsList();

    // eslint-disable-next-line
  }, [open]);

  const getActorsList = async () => {
    try {
      const { data } = await apiClient.get("/admin_creative_get_actors");
      const list: Actor[] = data?.message || [];
      setActors(list);
    } catch (error) {
      console.log("error:", error);
    } finally {
    }
  };

  const fetchTargetTypes = async () => {
    try {
      const { data } = await apiClient.get("/admin_creative_get_target_types");
      const content: Target[] = data?.message || [];
      setTargetTypes(content);
    } catch (error) {
      console.error("Error:", error);
    } finally {
    }
  };

  const fetchPosterTypes = async () => {
    try {
      const { data } = await apiClient.get("/admin_creative_get_poster_types");
      const content: Poster[] = data?.message || [];
      setPosterTypes(content);
    } catch (error) {
      console.error("Error:", error);
    } finally {
    }
  };

  const addHintSchedule = async (evt: React.FormEvent) => {
    evt.preventDefault();
    setSubmitting(true);
    if (Boolean(editData)) {
      try {
        await apiClient.post(
          "/admin_creative_update_hint_schedule",
          {},
          {
            headers: {
              request: JSON.stringify({
                hint_schedule_id: editData?.hint_schedule_id,
                hint_subject_id: hint_subject?.hint_subject_id,
                poster_type: posterType?.content_type,
                poster_id: poster?.actor_id || 0,
                target_type: targetType?.target_id,
                target_id: target?.actor_id || 0,
                hours_between: hoursBetween,
              }),
            },
          }
        );
        onOpenChange(false);
        fetchData();
      } catch (error) {
        console.log("Error:", error);
      } finally {
        setSubmitting(false);
      }
    } else {
      try {
        await apiClient.post(
          "/admin_creative_add_hint_schedule",
          {},
          {
            headers: {
              request: JSON.stringify({
                hint_subject_id: hint_subject?.hint_subject_id,
                poster_type: posterType?.content_type,
                poster_id: poster?.actor_id || 0,
                target_type: targetType?.target_id,
                target_id: target?.actor_id || 0,
                hours_between: hoursBetween,
              }),
            },
          }
        );
        onOpenChange(false);
        fetchData();
      } catch (error) {
        console.log("Error:", error);
      } finally {
        setSubmitting(false);
      }
    }
  };

  return (
    <>
      <Dialog open={open} onOpenChange={onOpenChange}>
        <DialogContent className="sm:max-w-[425px]">
          <DialogHeader>
            <DialogTitle>
              {!!editData ? "Edit" : "Add"} hint schedule
            </DialogTitle>
          </DialogHeader>

          <form onSubmit={addHintSchedule} className="w-full grid gap-4">
            <div className="grid gap-2">
              <Label htmlFor="poster-type">Poster type</Label>
              <div className="flex gap-2">
                <Select
                  required
                  value={String(posterType?.content_type)}
                  onValueChange={(value) => {
                    if (value === "no data") return;
                    const poster = posterTypes.filter(
                      (p) => p.content_type === parseInt(value)
                    );
                    setPosterType(poster[0] || null);
                    setPoster(null);
                    const posters = actors.filter(
                      (item) =>
                        item.actor_type === poster[0]?.content_type &&
                        item.creative_id === account?.account_id
                    );
                    setPosterList(posters);
                  }}
                >
                  <SelectTrigger type="button" className="w-full">
                    <SelectValue placeholder={"Select poster type"} />
                  </SelectTrigger>
                  <SelectContent>
                    {posterTypes.length > 0 ? (
                      posterTypes.map((item) => (
                        <SelectItem
                          key={item.content_type}
                          value={String(item.content_type)}
                        >
                          {item.description}
                        </SelectItem>
                      ))
                    ) : (
                      <SelectItem value={"no data"}>No data</SelectItem>
                    )}
                  </SelectContent>
                </Select>
              </div>
            </div>

            <div className="grid gap-2">
              <Label htmlFor="poster">Poster</Label>
              <div className="flex gap-2">
                <Select
                  required
                  disabled={!Boolean(posterType)}
                  value={String(poster?.actor_id)}
                  onValueChange={(value) => {
                    if (value === "no data") return;
                    const _poster = posterList.filter(
                      (d) => d.actor_id === parseInt(value)
                    );
                    setPoster(_poster[0] || null);
                  }}
                >
                  <SelectTrigger type="button" className="w-full">
                    <SelectValue placeholder={"Select poster"} />
                  </SelectTrigger>
                  <SelectContent>
                    {posterList.length > 0 ? (
                      posterList.map((item) => (
                        <SelectItem
                          key={item.actor_id}
                          value={String(item.actor_id)}
                        >
                          {item.actor_name}
                        </SelectItem>
                      ))
                    ) : (
                      <SelectItem value={"no data"}>No data</SelectItem>
                    )}
                  </SelectContent>
                </Select>
              </div>
            </div>

            <div className="grid gap-2">
              <Label htmlFor="target-type">Target type</Label>
              <div className="flex gap-2">
                <Select
                  required
                  value={String(targetType?.target_id)}
                  onValueChange={(value) => {
                    if (value === "no data") return;
                    const target = targetTypes.filter(
                      (t) => t.target_id === parseInt(value)
                    );
                    setTargetType(target[0] || null);
                    setTarget(null);

                    const posters = actors.filter(
                      (item) =>
                        item.actor_type === target[0]?.target_id &&
                        item.creative_id === account?.account_id
                    );
                    setTargetList(posters);
                  }}
                >
                  <SelectTrigger type="button" className="w-full">
                    <SelectValue placeholder={"Select target type"} />
                  </SelectTrigger>
                  <SelectContent>
                    {targetTypes.length > 0 ? (
                      targetTypes.map((target) => (
                        <SelectItem
                          key={target.target_id}
                          value={String(target.target_id)}
                        >
                          {target.description}
                        </SelectItem>
                      ))
                    ) : (
                      <SelectItem value={"no data"}>No data</SelectItem>
                    )}
                  </SelectContent>
                </Select>
              </div>
            </div>

            <div className="grid gap-2">
              <Label htmlFor="target">Target</Label>
              <div className="flex gap-2">
                <Select
                  required
                  disabled={!Boolean(targetType)}
                  value={String(target?.actor_id)}
                  onValueChange={(value) => {
                    if (value === "no data") return;
                    const _target = targetList.filter(
                      (d) => d.actor_id === parseInt(value)
                    );
                    setTarget(_target[0] || null);
                  }}
                >
                  <SelectTrigger type="button" className="w-full">
                    <SelectValue placeholder={"Select target type"} />
                  </SelectTrigger>
                  <SelectContent>
                    {targetList.length > 0 ? (
                      targetList.map((item) => (
                        <SelectItem
                          key={item.actor_id}
                          value={String(item.actor_id)}
                        >
                          {item.actor_name}
                        </SelectItem>
                      ))
                    ) : (
                      <SelectItem value={"no data"}>No data</SelectItem>
                    )}
                  </SelectContent>
                </Select>
              </div>
            </div>

            <div className="grid gap-2">
              <Label htmlFor="hours">Hours between</Label>
              <Input
                required
                type="number"
                id="hours"
                value={hoursBetween || ""}
                onChange={(evt) => {
                  const val = evt.target.value;
                  if (parseInt(val) < 0) return;
                  setHoursBetween(parseInt(val));
                }}
              />
            </div>

            <DialogFooter>
              <DialogClose asChild>
                <Button
                  disabled={submitting}
                  type="button"
                  size="sm"
                  variant="outline"
                >
                  Cancel
                </Button>
              </DialogClose>
              <Button disabled={submitting} type="submit" size="sm">
                {submitting && (
                  <RefreshCw className="size-4 animate-spin mr-2" />
                )}{" "}
                Save
              </Button>
            </DialogFooter>
          </form>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default AddHintScheduleDialog;
