import { useEffect, useRef, useState } from "react";
import { RefreshCw } from "lucide-react";
import { Button } from "components/ui/button";

import {
  Sheet,
  SheetContent,
  SheetDescription,
  SheetFooter,
  SheetHeader,
  SheetTitle,
} from "components/ui/sheet";

import FacebookLogin from "components/SocialLogin/FacebookLogin";
import LinkedinLogin from "components/SocialLogin/LinkedinLogin";
import TwitterLogin from "components/SocialLogin/TwitterLogin";
import { useAppState } from "context/AppContext";
import { useConnection } from "context/ConnectionContext";
import { getCreateEndpoint } from "utils/helper-methods";
import WordpressAddDialog from "../Wordpress/WordpressDialog";
import apiClient from "api";

const redirect_uri = process.env.REACT_APP_REDIRECT_URI || "";

const SetupConnection = () => {
  const {
    isOpen,
    isCreating,
    isReconnecting,
    connectionData,
    setIsOpen,
    setProvider,
    setDataToSend,
    setIsCreating,
    setIsCancelled,
    setConnectionData,
    setOpenStatusModal,
    setConnectionState,
    setIsAuthenticated,
    setIsAuthenticating,
  } = useConnection();
  const { state } = useAppState();
  const { account_token } = state.user;

  // const advanceLogin = useRef<boolean>(false);
  const socialState = useRef<string>("");
  const twitterAppId = useRef<string>("");
  // const twitterAppSecret = useRef<string>("");
  const [social, setSocial] = useState("");
  const linkedin = useRef<HTMLButtonElement>(null);
  const facebook = useRef<HTMLButtonElement>(null);
  const twitter = useRef<HTMLButtonElement>(null);
  const [openWordpress, setOpenWordpress] = useState(false);

  useEffect(() => {
    if (!isReconnecting && !connectionData) return;
    socialState.current = connectionData!.state;
    const timeout = setTimeout(() => {
      handleClick(connectionData!.channel.toLowerCase());
    }, 500);

    return () => clearTimeout(timeout);
    // eslint-disable-next-line
  }, [isReconnecting]);

  const createConnection = async (connection: string) => {
    setSocial(connection);
    const endpoint = getCreateEndpoint(connection);
    if (!endpoint || isCreating) return;
    setIsCreating(true);
    try {
      const { data } = await apiClient.post(
        endpoint,
        {},
        {
          headers: {
            token: account_token,
          },
        }
      );
      if (data?.message.channel === "twitter") {
        twitterAppId.current = data?.message.application_id || "";
        // twitterAppSecret.current = data?.message.application_secret || "";
      }
      socialState.current = data?.message.state;
      setConnectionState(data?.message.state);
      setConnectionData(data?.message);
      setTimeout(() => {
        handleClick(connection);
        setIsCreating(false);
      }, 500);
    } catch (error) {
      setIsCreating(false);
    }
  };

  const handleClick = (connection: string) => {
    setSocial(connection);
    if (connection === "facebook" && facebook.current) {
      setTimeout(() => {
        facebook.current?.click();
      }, 500);
    }
    if (connection === "linkedin" && linkedin.current) {
      linkedin.current.click();
    }
    if (connection === "twitter" && twitter.current) {
      twitter.current.click();
    }
  };

  return (
    <Sheet open={isOpen} onOpenChange={setIsOpen} modal>
      <SheetContent>
        <SheetHeader>
          <SheetTitle>
            {isReconnecting ? "Reconnect connection" : "New connection"}
          </SheetTitle>
          {!isReconnecting && (
            <SheetDescription>
              Choose the connection you want to add.
            </SheetDescription>
          )}
        </SheetHeader>

        <div className="grid gap-4 py-4">
          <LinkedinLogin
            redirect_uri={redirect_uri}
            scope={
              // advanceLogin
              //   ? "r_organization_followers,r_organization_social,rw_organization_admin,r_organization_social_feed,w_member_social,w_organization_social,r_basicprofile,w_organization_social_feed,w_member_social_feed,r_1st_connections_size" :

              "w_member_social,openid,profile,email"
            }
            state={isReconnecting ? connectionData?.state : socialState.current}
            client_id={process.env.REACT_APP_LINKEDIN_APP_ID || ""}
            client_secret={process.env.REACT_APP_LINKEDIN_APP_SECRET || ""}
            isOnlyGetCode
            isOnlyGetToken
            onLoginStart={() => {
              setProvider("linkedin");
              setIsAuthenticating(true);
            }}
            onReject={() => {
              setOpenStatusModal(true);
              setIsCancelled(true);
              setIsAuthenticating(false);
            }}
            onResolve={({ data }) => {
              // linkedinRetrieve(`code=${data?.code}&state=${state}`);
              setDataToSend(`code=${data?.code}&state=${socialState.current}`);
              setOpenStatusModal(true);
              setIsAuthenticated(true);
              setIsAuthenticating(false);
            }}
          >
            <button className="hidden" ref={linkedin}></button>
          </LinkedinLogin>
          <FacebookLogin
            appId={process.env.REACT_APP_FB_APP_ID || ""}
            scope="email,public_profile,pages_manage_posts,pages_show_list,read_insights,pages_read_user_content,pages_read_engagement,pages_manage_metadata,business_management"
            isOnlyGetToken
            return_scopes
            cookie={false}
            version="v17.0"
            redirect_uri={redirect_uri}
            onLoginStart={() => {
              setProvider("facebook");
              setIsAuthenticating(true);
            }}
            onReject={() => {
              setOpenStatusModal(true);
              setIsCancelled(true);
              setIsAuthenticating(false);
            }}
            onResolve={({ data }) => {
              // facebookRetrieve(data, "facebook");
              setDataToSend({
                state: socialState.current,
                access_token: data?.accessToken,
                time_to_expiry: data?.data_access_expiration_time,
                scope: data?.grantedScopes,
                signed_request: data?.signedRequest,
                facebook_user_id: parseInt(data?.userID),
              });
              setOpenStatusModal(true);
              setIsAuthenticated(true);
              setIsAuthenticating(false);
            }}
          >
            <button className="hidden" ref={facebook}></button>
          </FacebookLogin>
          <TwitterLogin
            client_id={
              twitterAppId.current || process.env.REACT_APP_X_CLIENT_ID || ""
            }
            state={isReconnecting ? connectionData?.state : socialState.current}
            scope="tweet.read%20users.read%20follows.read%20follows.write%20offline.access%20tweet.write"
            redirect_uri={redirect_uri}
            isOnlyGetCode
            onLoginStart={() => {
              setProvider("twitter");
              setIsAuthenticating(true);
            }}
            onReject={() => {
              setOpenStatusModal(true);
              setIsCancelled(true);
              setIsAuthenticating(false);
            }}
            onResolve={({ data }) => {
              setDataToSend({
                uri: redirect_uri,
                state: socialState.current,
                auth_code: data?.code ?? "",
                code_challenge: "challenge",
              });
              setOpenStatusModal(true);
              setIsAuthenticated(true);
              setIsAuthenticating(false);
            }}
          >
            <button className="hidden" ref={twitter}></button>
          </TwitterLogin>

          <Button
            variant="outline"
            className="w-full"
            disabled={isCreating || isReconnecting}
            onClick={() => createConnection("linkedin")}
          >
            LinkedIn
          </Button>
          {/* <DropdownMenu>
            <DropdownMenuTrigger asChild></DropdownMenuTrigger>
            <DropdownMenuContent className="w-60">
              <DropdownMenuLabel>Choose login method</DropdownMenuLabel>
              <DropdownMenuSeparator />
              <DropdownMenuItem
                disabled={isCreating || isReconnecting}
                onClick={() => {
                  advanceLogin.current = false;
                  createConnection("linkedin");
                }}
              >
                Default Login
              </DropdownMenuItem>
              <DropdownMenuItem
                disabled={isCreating || isReconnecting}
                onClick={() => {
                  advanceLogin.current = true;
                  createConnection("linkedin");
                }}
              >
                Advance Login
              </DropdownMenuItem>
            </DropdownMenuContent>
          </DropdownMenu> */}

          <Button
            variant="outline"
            className="w-full"
            disabled={isCreating || isReconnecting}
            onClick={() => createConnection("facebook")}
          >
            Facebook
          </Button>
          <Button
            variant="outline"
            className="w-full"
            disabled={isCreating || isReconnecting}
            onClick={() => createConnection("twitter")}
          >
            Twitter
          </Button>
          <Button
            variant="outline"
            disabled={isCreating || isReconnecting}
            onClick={() => {
              setOpenWordpress(true);
              setIsOpen(false);
            }}
          >
            Wordpress
          </Button>

          <Button variant="outline" disabled>
            Wix
          </Button>
          <Button variant="outline" disabled>
            Podcast
          </Button>

          {isCreating && (
            <div className="flex flex-col items-center space-y-4">
              <p className="text-sm text-center mt-4 inline-flex items-center">
                Please wait, while we take you to the {social} for
                authentication.
              </p>
              <span className="animate-spin">
                <RefreshCw className="size-5" />
              </span>{" "}
            </div>
          )}

          {isReconnecting && (
            <div className="flex flex-col items-center space-y-4">
              <p className="text-sm text-center mt-4 inline-flex items-center">
                Please wait, while we take you to the {social} to reconnect.
              </p>
              <span className="animate-spin">
                <RefreshCw className="size-5" />
              </span>{" "}
            </div>
          )}
        </div>

        <SheetFooter></SheetFooter>
      </SheetContent>

      <WordpressAddDialog
        open={openWordpress}
        onOpenChange={setOpenWordpress}
      />
    </Sheet>
  );
};

export default SetupConnection;
