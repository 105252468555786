import { useEffect, useState } from "react";
import { ChevronLeft, EditIcon, Plus, RefreshCw, Trash } from "lucide-react";
import {
  Dialog,
  DialogClose,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from "components/ui/dialog";
import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
} from "components/ui/alert-dialog";
import { Button } from "components/ui/button";
import { Textarea } from "components/ui/textarea";
import { Label } from "components/ui/label";
import { ScrollArea } from "components/ui/scroll-area";
import { Input } from "components/ui/input";
import { BlogSetting } from "types";
import apiClient from "api";

type Props = {
  data: BlogSetting;
  open: boolean;
  onOpenChange: (value: boolean) => void;
};

type BlogSubject = {
  blog_subject_id: string;
  max_words: number;
  subject: string;
};

const SubjectsDialog = ({ data: blog, open, onOpenChange }: Props) => {
  const [isAdding, setIsAdding] = useState(false);
  const [openAlert, setOpenAlert] = useState(false);
  const [loading, setLoading] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const [editData, setEditData] = useState<BlogSubject | null>(null);
  const [subjects, setSubjects] = useState<BlogSubject[]>([]);
  const [subject, setSubject] = useState("");
  const [maxWords, setMaxWords] = useState(0);

  useEffect(() => {
    if (!open) {
      setEditData(null);
      setSubjects([]);
      setSubject("");
      setMaxWords(0);
      setIsAdding(false);
    } else {
      fetchSubjects();
    }
    // eslint-disable-next-line
  }, [open]);

  const fetchSubjects = async () => {
    setLoading(true);
    try {
      const { data } = await apiClient.get(
        "/admin_creative_get_blog_subjects",
        {
          headers: {
            request: JSON.stringify({
              blog_post_id: blog.blog_post_settings_id,
            }),
          },
        }
      );
      setSubjects(data?.message || []);
    } catch (error) {
      console.log("Error:", error);
    } finally {
      setLoading(false);
    }
  };

  const addSubject = async () => {
    setSubmitting(true);
    if (Boolean(editData)) {
      try {
        await apiClient.post(
          "/admin_creative_update_blog_subject",
          {},
          {
            headers: {
              request: JSON.stringify({
                blog_subject_id: editData?.blog_subject_id,
                subject,
                max_words: maxWords,
              }),
            },
          }
        );
        fetchSubjects();
        setIsAdding(false);
        setEditData(null);
      } catch (error) {
        console.log("Error:", error);
      } finally {
        setSubmitting(false);
      }
    } else {
      try {
        await apiClient.post(
          "/admin_creative_add_blog_subject",
          {},
          {
            headers: {
              request: JSON.stringify({
                blog_post_id: blog.blog_post_settings_id,
                subject,
                max_words: maxWords,
              }),
            },
          }
        );
        fetchSubjects();
        setIsAdding(false);
        setEditData(null);
      } catch (error) {
        console.log("Error:", error);
      } finally {
        setSubmitting(false);
      }
    }
  };

  const deleteSubject = async () => {
    setIsDeleting(true);
    try {
      await apiClient.delete("/admin_creative_delete_blog_subject", {
        headers: {
          request: JSON.stringify({
            blog_subject_id: editData?.blog_subject_id,
          }),
        },
      });
      fetchSubjects();
      setEditData(null);
    } catch (error) {
      console.log("Error:", error);
    } finally {
      setIsDeleting(false);
    }
  };

  return (
    <>
      <Dialog open={open} onOpenChange={onOpenChange}>
        <DialogContent className="sm:max-w-[425px]">
          <DialogHeader>
            <DialogTitle>Blog subjects</DialogTitle>
          </DialogHeader>

          <div className="flex justify-between items-center mt-2">
            <div className="text-sm ">Subjects</div>
            {!isAdding ? (
              <Button
                size="sm"
                variant="secondary"
                onClick={() => {
                  setIsAdding(true);
                  setSubject("");
                }}
              >
                <Plus className="size-4 mr-2" />
                Add subject
              </Button>
            ) : (
              <Button
                size="sm"
                variant="secondary"
                onClick={() => {
                  setIsAdding(false);
                  setEditData(null);
                }}
              >
                <ChevronLeft className="size-4 mr-2" />
                Back
              </Button>
            )}
          </div>

          {!isAdding && (
            <ScrollArea className="h-96 w-full rounded-md">
              <div className="">
                {loading && subjects.length === 0 && (
                  <div className="mt-10 text-center">
                    <div className="flex items-center justify-center gap-2">
                      <div className="animate-spin w-4 h-4 rounded-full border-2 border-gray-200 border-t-gray-800 dark:border-gray-800 dark:border-t-gray-200" />
                      <span className="animate-pulse text-sm">Loading...</span>
                    </div>
                  </div>
                )}

                {subjects.length > 0
                  ? subjects.map((item) => (
                      <div
                        key={item.blog_subject_id}
                        className="flex flex-row items-center justify-between rounded-lg border p-2 shadow-sm mb-2"
                      >
                        <div className="space-y-1">
                          <div>
                            <div className="text-sm">{item.subject}</div>
                          </div>
                          <div className="flex items-center text-sm">
                            <div className="font-light">Max words:</div>
                            <div className="ml-1">{item.max_words}</div>
                          </div>
                        </div>

                        <div className="flex gap-2">
                          <Button
                            onClick={() => {
                              setIsAdding(true);
                              setEditData(item);
                              setSubject(item.subject);
                            }}
                            size="icon"
                            variant="secondary"
                            disabled={isDeleting}
                          >
                            <EditIcon className="size-4" />
                          </Button>
                          <Button
                            size="icon"
                            variant="secondary"
                            disabled={isDeleting}
                            onClick={() => {
                              setOpenAlert(true);
                              setEditData(item);
                            }}
                          >
                            <Trash className="size-4 text-red-500" />
                          </Button>
                        </div>
                      </div>
                    ))
                  : !loading && (
                      <div className="mt-10 text-center text-sm font-medium">
                        <span> No subjects</span>
                      </div>
                    )}
              </div>
            </ScrollArea>
          )}
          {isAdding && (
            <>
              <div className="grid gap-2">
                <Label htmlFor="subject">Subject</Label>
                <div className="relative">
                  <Textarea
                    id="subject"
                    value={subject}
                    onChange={(evt) => {
                      if (subject.length >= 500) return;
                      setSubject(evt.target.value);
                    }}
                    rows={6}
                    className="resize-none"
                  />
                  {/* <span className="absolute font-thin text-xs bottom-2 right-2">
                    {subject.length}/500
                  </span> */}
                </div>
              </div>

              <div className="grid gap-2">
                <Label htmlFor="hours">Max words</Label>
                <Input
                  type="number"
                  id="hours"
                  value={maxWords}
                  onChange={(evt) => {
                    const val = evt.target.value;
                    if (parseInt(val) < 0) return;
                    setMaxWords(parseInt(val));
                  }}
                />
              </div>

              <div className="flex justify-end gap-2">
                <Button
                  onClick={addSubject}
                  disabled={submitting}
                  size="sm"
                  variant="secondary"
                >
                  {submitting && (
                    <RefreshCw className="size-4 animate-spin mr-2" />
                  )}{" "}
                  Save subject
                </Button>
              </div>
            </>
          )}

          <DialogFooter>
            <DialogClose asChild>
              <Button type="button" size="sm" variant="outline">
                Close
              </Button>
            </DialogClose>
          </DialogFooter>
        </DialogContent>
      </Dialog>

      <AlertDialog open={openAlert} onOpenChange={setOpenAlert}>
        <AlertDialogContent>
          <AlertDialogHeader>
            <AlertDialogTitle>Are you sure?</AlertDialogTitle>
            <AlertDialogDescription>
              This action cannot be undone. This will permanently delete your
              subject.
            </AlertDialogDescription>
          </AlertDialogHeader>
          <AlertDialogFooter>
            <AlertDialogCancel>Cancel</AlertDialogCancel>
            <AlertDialogAction onClick={deleteSubject}>
              Confirm
            </AlertDialogAction>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialog>
    </>
  );
};

export default SubjectsDialog;
