import { useEffect, useState } from "react";
import { RefreshCw } from "lucide-react";
import {
  Dialog,
  DialogClose,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from "components/ui/dialog";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "components/ui/select";
import { Input } from "components/ui/input";
import { Label } from "components/ui/label";
import { Button } from "components/ui/button";
import { useAppState } from "context/AppContext";
import { Avatar, Connection } from "types";
import apiClient from "api";
import { Textarea } from "components/ui/textarea";

type Props = {
  open: boolean;
  editData?: Avatar | null;
  fetchData: () => void;
  onOpenChange: (value: boolean) => void;
};

const channels = [
  {
    value: 1,
    label: "Twitter",
  },
  {
    value: 2,
    label: "Facebook",
  },
  {
    value: 3,
    label: "LinkedIn",
  },
];

const AddAvatarDialog = ({
  open,
  editData,
  fetchData,
  onOpenChange,
}: Props) => {
  const { state } = useAppState();
  const { account } = state.admin;

  const [bio, setBio] = useState("");
  const [channel, setChannel] = useState(0);
  const [pagingId, setPagingId] = useState(0);
  const [avatarUrl, setAvatarUrl] = useState("");
  const [avatarName, setAvatarName] = useState("");
  const [submitting, setSubmitting] = useState(false);
  const [connections, setConnections] = useState<Connection[]>([]);
  const [connection, setConnection] = useState<Connection | null>(null);
  const [loadingConnections, setLoadingConnections] = useState(false);

  useEffect(() => {
    if (!editData) return;
    setBio(editData.bio || "");
    setAvatarName(editData.avatar_name || "");
    setAvatarUrl(editData.avatar_url || "");
    const _conn = connections.find((_d) => _d.id === editData.connection_id);
    setConnection(_conn || null);
    setChannel(editData.channel_id);
    setPagingId(editData.paging_id);

    // eslint-disable-next-line
  }, [editData, connections]);

  useEffect(() => {
    if (!open) return;
    const getConnections = async () => {
      setLoadingConnections(true);
      try {
        const { data } = await apiClient.get("/creative_get_social_dashboard", {
          headers: {
            request: JSON.stringify({ creative_id: account?.account_id }),
          },
        });

        const connections: Connection[] = data?.message || [];
        setConnections(connections);
      } catch (error) {
        console.log("error:", error);
      } finally {
        setLoadingConnections(false);
      }
    };

    getConnections();
    // eslint-disable-next-line
  }, [open]);

  const addAvatar = async (evt: React.FormEvent) => {
    evt.preventDefault();

    setSubmitting(true);
    if (Boolean(editData)) {
      try {
        await apiClient.post(
          "/admin_creative_update_avatar",
          {},
          {
            headers: {
              request: JSON.stringify({
                creative_id: account?.account_id,
                avatar_id: editData?.avatar_id,
                channel_id: channel,
                paging_id: pagingId,
                avatar_url: avatarUrl,
                avatar_name: avatarName,
                connection_id: connection?.id,
                author_id: 0,
                bio,
              }),
            },
          }
        );
        onOpenChange(false);
        fetchData();
      } catch (error) {
        console.log("Error:", error);
      } finally {
        setSubmitting(false);
      }
    } else {
      try {
        await apiClient.post(
          "/admin_creative_add_avatar",
          {},
          {
            headers: {
              request: JSON.stringify({
                author_id: 0,
                paging_id: pagingId,
                channel_id: channel,
                avatar_url: avatarUrl,
                avatar_name: avatarName,
                creative_id: account?.account_id,
                connection_id: connection?.id,
                bio,
              }),
            },
          }
        );
        onOpenChange(false);
        fetchData();
      } catch (error) {
        console.log("Error:", error);
      } finally {
        setSubmitting(false);
      }
    }
  };

  return (
    <>
      <Dialog open={open} onOpenChange={onOpenChange}>
        <DialogContent className="sm:max-w-[425px]">
          <DialogHeader>
            <DialogTitle>{!!editData ? "Edit" : "Add"} avatar</DialogTitle>
          </DialogHeader>

          <form onSubmit={addAvatar} className="grid gap-4">
            <div className="grid gap-2">
              <Label htmlFor="name">Avatar name</Label>
              <Input
                type="text"
                id="name"
                required
                value={avatarName}
                onChange={(evt) => {
                  const val = evt.target.value;
                  setAvatarName(val);
                }}
              />
            </div>

            <div className="grid gap-2">
              <Label htmlFor="url">Avatar url</Label>
              <Input
                type="url"
                id="url"
                required
                value={avatarUrl}
                onChange={(evt) => {
                  const val = evt.target.value;
                  setAvatarUrl(val);
                }}
              />
            </div>

            <div className="grid gap-2">
              <Label htmlFor="paging-id">Paging ID</Label>
              <div className="relative">
                <Input
                  id="paging-id"
                  type="number"
                  required
                  value={pagingId}
                  onChange={(evt) => {
                    const val = parseInt(evt.target.value);
                    if (val < 0) return;

                    setPagingId(val);
                  }}
                  className="resize-none"
                />
              </div>
            </div>

            <div className="grid gap-2">
              <Label htmlFor="bio">Bio</Label>
              <Textarea
                id="bio"
                rows={4}
                value={bio}
                onChange={(evt) => {
                  const val = evt.target.value;
                  setBio(val);
                }}
                className="resize-none"
              />
            </div>
            <div className="grid gap-2">
              <Label htmlFor="channel">Channel</Label>
              <Select
                value={String(channel)}
                onValueChange={(val) => {
                  setChannel(parseInt(val));
                }}
              >
                <SelectTrigger type="button" className="w-full">
                  <SelectValue placeholder={"Select channel"} />
                </SelectTrigger>
                <SelectContent>
                  {channels.map((item) => (
                    <SelectItem key={item.value} value={String(item.value)}>
                      {item.label}
                    </SelectItem>
                  ))}
                </SelectContent>
              </Select>
            </div>

            <div className="grid gap-2">
              <Label htmlFor="connection">Connection</Label>
              <Select
                value={String(connection?.id)}
                onValueChange={(val) => {
                  const _connection = connections.find(
                    (_d) => _d.id === parseInt(val)
                  );
                  setConnection(_connection || null);
                }}
              >
                <SelectTrigger type="button" className="w-full">
                  <SelectValue
                    placeholder={
                      loadingConnections
                        ? "Loading connections..."
                        : "Select connection"
                    }
                  />
                </SelectTrigger>
                <SelectContent>
                  {connections.length > 0 ? (
                    connections.map((item) => (
                      <SelectItem key={item.id} value={String(item.id)}>
                        {item.connection_name || "ID - " + item.id}
                      </SelectItem>
                    ))
                  ) : (
                    <SelectItem value={"no-data"}>No data</SelectItem>
                  )}
                </SelectContent>
              </Select>
            </div>

            <DialogFooter>
              <DialogClose asChild>
                <Button
                  disabled={submitting}
                  type="button"
                  size="sm"
                  variant="outline"
                >
                  Cancel
                </Button>
              </DialogClose>
              <Button disabled={submitting} type="submit" size="sm">
                {submitting && (
                  <RefreshCw className="size-4 animate-spin mr-2" />
                )}{" "}
                Save
              </Button>
            </DialogFooter>
          </form>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default AddAvatarDialog;
