import { Bar, BarChart, CartesianGrid, XAxis } from "recharts";

import {
  Card,
  CardContent,
  CardDescription,
  CardHeader,
  CardTitle,
} from "components/ui/card";
import {
  ChartConfig,
  ChartContainer,
  ChartTooltip,
  ChartTooltipContent,
} from "components/ui/chart";

export const description = "An interactive bar chart";

interface ChartData {
  id: number;
  date: string;
  clickThruRate: number;
}

const chartConfig = {
  clickThruRate: {
    label: "Click thru rate",
    color: "hsl(var(--chart-1))",
  },
  id: {
    label: "Post ID",
    color: "hsl(var(--chart-1))",
  },
} satisfies ChartConfig;

interface ChartProps {
  data: ChartData[];
  isLoading: boolean;
}

export function PostsChart({ data }: ChartProps) {
  return (
    <Card>
      <CardHeader className="flex flex-col items-stretch space-y-0 border-b p-0 sm:flex-row">
        <div className="flex flex-1 flex-col justify-center gap-1 px-6 py-5 sm:py-6">
          <CardTitle>Click thru rate</CardTitle>
          <CardDescription>
            {/* Showing total visitors for the last 3 months */}
          </CardDescription>
        </div>
      </CardHeader>
      <CardContent className="px-2 sm:p-6">
        <ChartContainer
          config={chartConfig}
          className="aspect-auto h-[350px] w-full"
        >
          <BarChart
            accessibilityLayer
            data={data}
            margin={{
              left: 12,
              right: 12,
            }}
          >
            <CartesianGrid vertical={false} />
            <XAxis
              dataKey="id" // Changed from "date" to "id"
              tickLine={false}
              axisLine={false}
              tickMargin={8}
              minTickGap={32}
            />
            <ChartTooltip
              content={
                <ChartTooltipContent
                  indicator="line"
                  className="w-[180px]"
                  nameKey="clickThruRate"
                  labelFormatter={(value, payload) => {
                    const item = payload[0]?.payload;
                    return (
                      <>
                        <div>
                          <strong>Date:</strong>{" "}
                          {new Date(item?.date).toLocaleDateString("en-US", {
                            month: "short",
                            day: "numeric",
                            year: "numeric",
                          })}
                        </div>
                        <div>
                          <strong>ID:</strong> {item?.id}
                        </div>
                      </>
                    );
                  }}
                />
              }
            />

            <Bar
              dataKey={"clickThruRate"}
              fill={`var(--color-clickThruRate)`}
            />
          </BarChart>
        </ChartContainer>
      </CardContent>
    </Card>
  );
}
