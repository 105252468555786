import { memo, useEffect, useState } from "react";
import { columns } from "components/Tables/posts/columns";
import { DataTable } from "components/Tables/posts/data-table";
import { useAppState } from "context/AppContext";
import { Post } from "types";
import apiClient from "api";

const Posts = () => {
  const { state, dispatch } = useAppState();
  const { account_token, refresh } = state.user;

  const [isLoading, setIsLoading] = useState(false);
  const [posts, setPosts] = useState<Post[]>([]);

  useEffect(() => {
    if (refresh !== "posts") return;
    const timeout = setTimeout(fetchPosts, 100);
    return () => clearTimeout(timeout);
    // eslint-disable-next-line
  }, [refresh]);

  useEffect(() => {
    if (!account_token) return;
    const timeout = setTimeout(fetchPosts, 100);
    return () => clearTimeout(timeout);
    // eslint-disable-next-line
  }, [account_token]);

  const fetchPosts = async () => {
    setIsLoading(true);

    const request: {
      page_start: number;
      page_size: number;
      id?: number;
      schedule_id?: number;
    } = {
      page_size: 50,
      page_start: 1,
    };

    try {
      const { data }: { data: { message: Post[] } } = await apiClient.get(
        "/get_social_posts",
        {
          headers: {
            token: account_token,
            request: JSON.stringify(request),
          },
        }
      );

      let postsData = data?.message || [];

      if (postsData.length > 0) {
        postsData = postsData.filter((post) => post.article_title !== null);
      }

      setPosts(postsData);
      setIsLoading(false);
      if (refresh === "posts") dispatch({ type: "SET_REFRESH", payload: "" });
    } catch (error) {
      setIsLoading(false);
    }
  };
  return (
    <div className="flex flex-col space-y-8 pb-4 lg:p-8">
      <div className="flex items-center justify-between space-y-2">
        <div>
          <h2 className="text-2xl font-bold tracking-tight">Posts</h2>
          <p className="text-muted-foreground">
            Here&apos;s a list of your posts.
          </p>
        </div>
        <div className="flex items-center space-x-2"></div>
      </div>
      <DataTable data={posts} isLoading={isLoading} columns={columns} />
    </div>
  );
};

export default memo(Posts);
