import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import {
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbList,
  BreadcrumbPage,
  BreadcrumbSeparator,
} from "components/ui/breadcrumb";
import { Popover, PopoverContent, PopoverTrigger } from "components/ui/popover";
import {
  Command,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem,
  CommandList,
} from "components/ui/command";
import { CaretSortIcon } from "@radix-ui/react-icons";
import { Check } from "lucide-react";
import { Button } from "components/ui/button";
import { cn } from "lib/utils";

import { useAppState } from "context/AppContext";
import AccountSelect from "../AccountSelect";
import { DataTableGroupAnswerers } from "./data";
import AddGroupAnswererDialog from "./AddGroupAnswererDialog";
import { Group, GroupAnswerer } from "types";
import apiClient from "api";

const GroupAnswerers = () => {
  const { state, dispatch } = useAppState();
  const { agency, client, account, group: select_group } = state.admin;

  const [open, setOpen] = useState(false);
  const [loadingGroup, setLoadingGroup] = useState(false);
  const [groups, setGroups] = useState<Group[]>([]);

  const [data, setData] = useState<GroupAnswerer[]>([]);
  const [loading, setLoading] = useState(false);
  const [openAdd, setOpenAdd] = useState(false);

  useEffect(() => {
    setData([]);
    if (!account || !select_group) return;
    const timeout = setTimeout(fetchAnswerers, 200);
    return () => clearTimeout(timeout);
    // eslint-disable-next-line
  }, [account, select_group]);

  useEffect(() => {
    if (!account) return;
    const timeout = setTimeout(fetchGroups, 200);
    return () => clearTimeout(timeout);
    // eslint-disable-next-line
  }, [account]);

  const fetchGroups = async () => {
    setLoadingGroup(true);
    try {
      const { data } = await apiClient.get("admin_creative_get_groups", {
        headers: {
          request: JSON.stringify({
            creative_id: account?.account_id,
          }),
        },
      });
      setGroups(data.message || []);
    } catch (error) {
      console.log("Error:", error);
    } finally {
      setLoadingGroup(false);
    }
  };

  const fetchAnswerers = async () => {
    setLoading(true);
    try {
      const { data } = await apiClient.get(
        "admin_creative_get_group_answerers",
        {
          headers: {
            request: JSON.stringify({
              group_id: select_group?.group_id,
            }),
          },
        }
      );
      setData(data.message || []);
    } catch (error) {
      console.log("Error:", error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div>
      <Breadcrumb className="mb-4">
        <BreadcrumbList>
          <BreadcrumbItem>
            <Link to="/">Home</Link>
          </BreadcrumbItem>
          <BreadcrumbSeparator />
          <BreadcrumbItem>
            <Link to="/admin/agencies">{agency?.agency_name}</Link>
          </BreadcrumbItem>
          <BreadcrumbSeparator />
          <BreadcrumbItem>
            <Link to="/admin/clients">{client?.customer_name}</Link>
          </BreadcrumbItem>
          <BreadcrumbSeparator />
          <BreadcrumbItem>
            <Link to="/admin/accounts">{account?.account_name}</Link>
          </BreadcrumbItem>
          <BreadcrumbSeparator />
          <BreadcrumbItem>
            <BreadcrumbPage>Group answerers</BreadcrumbPage>
          </BreadcrumbItem>
        </BreadcrumbList>
      </Breadcrumb>

      <div className="space-y-2">
        <div className="flex justify-between items-center">
          <h3 className="scroll-m-20 text-2xl font-bold tracking-tight">
            Group answerers
          </h3>
          <AccountSelect />
        </div>
      </div>

      <div className="mt-8">
        <div className="flex justify-between items-center">
          <div className="flex items-center space-x-4">
            <p className="text-sm hidden md:inline-block text-secondary-foreground font-bold">
              Group
            </p>
            <Popover open={open} onOpenChange={setOpen}>
              <PopoverTrigger asChild disabled={loading}>
                <Button
                  variant="outline"
                  size="sm"
                  className="w-[150px] justify-start"
                >
                  <span className="truncate">{select_group?.group_name}</span>

                  <CaretSortIcon className="mr-2 h-4 w-4 ml-auto" />
                </Button>
              </PopoverTrigger>
              <PopoverContent className="p-0" side="right" align="start">
                <Command>
                  <CommandInput placeholder="Search account..." />
                  <CommandList>
                    <CommandEmpty>No results found.</CommandEmpty>
                    <CommandGroup>
                      {loadingGroup ? (
                        <CommandItem>Loading...</CommandItem>
                      ) : (
                        groups.map((group) => (
                          <CommandItem
                            key={group.group_id}
                            value={String(group.group_name)}
                            onSelect={() => {
                              dispatch({
                                type: "SET_ADMIN_GROUP",
                                payload: group,
                              });
                              setOpen(false);
                            }}
                          >
                            <Check
                              className={cn(
                                "mr-2 h-4 w-4",
                                group.group_id === select_group?.group_id
                                  ? "opacity-100"
                                  : "opacity-0"
                              )}
                            />
                            <span>{group.group_name}</span>
                          </CommandItem>
                        ))
                      )}
                    </CommandGroup>
                  </CommandList>
                </Command>
              </PopoverContent>
            </Popover>
          </div>
          <Button
            size="sm"
            onClick={() => setOpenAdd(true)}
            disabled={!Boolean(select_group)}
          >
            Add group answerer
          </Button>
        </div>
      </div>

      <DataTableGroupAnswerers
        data={data}
        loading={loading}
        fetchData={fetchAnswerers}
      />

      <AddGroupAnswererDialog
        fetchData={fetchAnswerers}
        open={openAdd}
        onOpenChange={setOpenAdd}
      />
    </div>
  );
};

export default GroupAnswerers;
