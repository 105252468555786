import { useEffect, useState } from "react";
import { Row } from "@tanstack/react-table";
import { MoreHorizontal } from "lucide-react";
import {
  Dialog,
  DialogClose,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from "components/ui/dialog";
import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
} from "components/ui/alert-dialog";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from "components/ui/dropdown-menu";
import { Button } from "components/ui/button";
import { AuthorCampaign } from "types";
import { Input } from "components/ui/input";
import { useToast } from "components/ui/use-toast";
import { Label } from "components/ui/label";
import apiClient from "api";

interface DataTableRowActionsProps<TData> {
  row: Row<TData>;
  fetchData: () => void;
}

export function CampaignRowActions({
  row,
  fetchData,
}: DataTableRowActionsProps<AuthorCampaign>) {
  const campaign = row.original;
  const { toast } = useToast();
  const [openAlert, setOpenAlert] = useState(false);
  const [openQtyEdit, setOpenQtyEdit] = useState(false);
  const [customQty, setCustomQty] = useState("");

  useEffect(() => {
    if (!openQtyEdit) {
      setCustomQty("");
    } else {
      setCustomQty(row.original.article_count.toString());
    }
    // eslint-disable-next-line
  }, [openQtyEdit]);

  const handleSaveName = async () => {
    toast({
      description: "Updating quantity",
    });
    try {
      await apiClient.post(
        "/admin_creative_update_content_quantity",
        {},
        {
          headers: {
            request: JSON.stringify({
              campaign_id: campaign.campaign_id,
              quantity: parseInt(customQty),
            }),
          },
        }
      );

      toast({
        description: "Campaign quantity updated successfully.",
      });
      fetchData();
    } catch (error) {
      toast({
        description: "Campaign quantity update failed.",
      });
    }
  };

  const deleteCampaign = async () => {
    toast({
      description: "Deleting campaign...",
      variant: "destructive",
    });
    try {
      await apiClient.delete("/admin_creative_delete_campaign", {
        headers: {
          request: JSON.stringify({ campaign_id: campaign.campaign_id }),
        },
      });
      fetchData();
      toast({
        description: "Campaign deleted successfully.",
        variant: "destructive",
      });
    } catch (error: any) {
      toast({
        description: "Campaign deletion failed.",
        variant: "destructive",
      });
    }
  };

  return (
    <>
      <DropdownMenu>
        <DropdownMenuTrigger asChild>
        <Button variant="ghost" className="h-6 w-6 p-0">
            <span className="sr-only">Open menu</span>
            <MoreHorizontal className="h-3 w-3" />
          </Button>
        </DropdownMenuTrigger>
        <DropdownMenuContent align="end">
          <DropdownMenuLabel>Actions</DropdownMenuLabel>
          <DropdownMenuItem onClick={() => setOpenQtyEdit(true)}>
            Edit article count
          </DropdownMenuItem>
          <DropdownMenuSeparator />
          <DropdownMenuItem
            className="text-red-600"
            onClick={() => setOpenAlert(true)}
          >
            Delete
          </DropdownMenuItem>
        </DropdownMenuContent>
      </DropdownMenu>
      <Dialog open={openQtyEdit} onOpenChange={setOpenQtyEdit}>
        <DialogContent className="sm:max-w-md">
          <DialogHeader>
            <DialogTitle>Edit article quantity</DialogTitle>
          </DialogHeader>
          <div className="my-0">
            <div className="mt-4 space-y-2">
              <Label htmlFor="name" className="text-right">
                Article quantity
              </Label>
              <Input
                autoFocus
                type="number"
                value={customQty}
                onChange={(evt) => setCustomQty(evt.target.value)}
                id="name"
              />
            </div>
          </div>
          <DialogFooter className="">
            <DialogClose asChild>
              <Button onClick={handleSaveName}>Save</Button>
            </DialogClose>
            <DialogClose asChild>
              <Button type="button" variant="secondary">
                Close
              </Button>
            </DialogClose>
          </DialogFooter>
        </DialogContent>
      </Dialog>

      <AlertDialog open={openAlert} onOpenChange={setOpenAlert}>
        <AlertDialogContent>
          <AlertDialogHeader>
            <AlertDialogTitle>Are you sure?</AlertDialogTitle>
            <AlertDialogDescription>
              This action cannot be undone. This will permanently delete your
              campaign and all associated articles.
            </AlertDialogDescription>
          </AlertDialogHeader>
          <AlertDialogFooter>
            <AlertDialogCancel>Cancel</AlertDialogCancel>
            <AlertDialogAction onClick={deleteCampaign}>
              Confirm
            </AlertDialogAction>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialog>
    </>
  );
}
