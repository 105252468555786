import { useEffect, useState } from "react";
import { Plus } from "lucide-react";

import { Button } from "components/ui/button";
import { columns } from "components/Tables/wordpress/columns";
import { DataTable } from "components/Tables/wordpress/data-table";
import { useAppState } from "context/AppContext";
import { useConnection } from "context/ConnectionContext";
import { WordpressConnection } from "types";
import apiClient from "api";

const Wordpress = () => {
  const { state, dispatch } = useAppState();
  const { setIsOpen } = useConnection();
  const { account, refresh } = state.user;
  const [connections, setConnections] = useState<WordpressConnection[]>([]);
  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    if (refresh !== "wordpress") return;
    const timeout = setTimeout(fetchWpConnections, 100);
    return () => clearTimeout(timeout);
    // eslint-disable-next-line
  }, [refresh]);

  useEffect(() => {
    if (!account) return;
    const timeout = setTimeout(fetchWpConnections, 100);
    return () => clearTimeout(timeout);
    // eslint-disable-next-line
  }, [account]);

  const fetchWpConnections = async () => {
    try {
      const { data } = await apiClient.get(
        "/creative_get_wordpress_connections",
        {
          headers: {
            request: JSON.stringify({ creative_id: account?.account_id }),
          },
        }
      );

      const connections: WordpressConnection[] = data?.message || [];
      setConnections(connections);
      if (refresh === "wordpress") {
        dispatch({ type: "SET_REFRESH", payload: "" });
      }
    } catch (error) {
      console.error("Error fetching connections:", error);
    } finally {
      setLoading(false);
    }
    // eslint-disable-next-line
  };

  return (
    <div className="flex flex-col space-y-8 pb-4 lg:p-8">
      <div className="flex items-center justify-between space-y-2">
        <div>
          <h2 className="text-2xl font-bold tracking-tight">
            Website connections
          </h2>
          <p className="text-muted-foreground">
            Here&apos;s a list of your connections.
          </p>
        </div>
        <div className="flex items-center space-x-2">
          <Button size="sm" variant="default" onClick={() => setIsOpen(true)}>
            <Plus className="size-4 mr-2" /> Add connection
          </Button>
        </div>
      </div>
      <DataTable loading={loading} data={connections} columns={columns} />
    </div>
  );
};

export default Wordpress;
