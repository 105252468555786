import { TOKEN_KEY } from "constants/constants";

export interface AuthState {
  isAuthenticating: boolean,
  isAuthenticated: boolean,
  user: any | null
  token: string | null;
  isAdmin: boolean
}

export const initialAuthState: AuthState = {
  isAuthenticating: true,
  isAuthenticated: false,
  user: null,
  token: null,
  isAdmin: false
}

export type AuthAction =
  | { type: "LOGIN"; payload: { user: any; token: string } }
  | { type: "REFRESH_TOKEN", payload: boolean }
  | { type: "SET_ADMIN", payload: boolean }
  | { type: "LOGOUT" }



const settingsReducer = (state: AuthState, action: AuthAction): AuthState => {
  switch (action.type) {
    case 'LOGIN':
      return { ...state, isAuthenticating: false, isAuthenticated: true, user: action.payload.user, token: action.payload.token };
    case 'REFRESH_TOKEN':
      return { ...state, isAuthenticating: action.payload };
    case 'SET_ADMIN':
      return { ...state, isAdmin: action.payload };
    case 'LOGOUT':
      localStorage.removeItem(TOKEN_KEY)
      localStorage.removeItem("USER_STATE")
      return { ...state, isAuthenticated: false, user: null, token: null, isAdmin: false };
    default:
      return state;
  }

}

export default settingsReducer