import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import {
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbList,
  BreadcrumbPage,
  BreadcrumbSeparator,
} from "components/ui/breadcrumb";
import { Button } from "components/ui/button";
import { Popover, PopoverContent, PopoverTrigger } from "components/ui/popover";
import {
  Command,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem,
  CommandList,
} from "components/ui/command";
import { CaretSortIcon } from "@radix-ui/react-icons";
import { Check } from "lucide-react";
import { cn } from "lib/utils";

import AccountSelect from "../AccountSelect";
import { useAppState } from "context/AppContext";
import { DataTableQuestionSchedules } from "./data";
import AddQuestionScheduleDialog from "./AddQuestionScheduleDialog";
import { QuestionSchedule, QuestionSubject } from "types";
import apiClient from "api";

const QuestionSchedules = () => {
  const { state, dispatch } = useAppState();
  const { agency, client, account, question_subject } = state.admin;

  const [openSubject, setOpenSubject] = useState(false);
  const [loadingSubjects, setLoadingSubjects] = useState(false);
  const [subjects, setSubjects] = useState<QuestionSubject[]>([]);
  const [data, setData] = useState<QuestionSchedule[]>([]);
  const [loading, setLoading] = useState(false);
  const [openAddSetting, setOpenAddSetting] = useState(false);

  useEffect(() => {
    setData([]);
    if (!account || !question_subject) return;

    const timeout = setTimeout(fetchQuestionSchedules, 200);
    return () => clearTimeout(timeout);
    // eslint-disable-next-line
  }, [account, question_subject]);

  useEffect(() => {
    if (!account) return;

    const timeout = setTimeout(fetchQuestionSubjects, 200);
    return () => clearTimeout(timeout);
    // eslint-disable-next-line
  }, [account]);

  const fetchQuestionSchedules = async () => {
    setLoading(true);
    try {
      const { data } = await apiClient.get(
        "/admin_creative_get_question_schedules",
        {
          headers: {
            request: JSON.stringify({
              creative_id: account?.account_id,
              question_subject_id: question_subject?.question_subject_id,
            }),
          },
        }
      );
      setData(data.message || []);
    } catch (error) {
      console.log("Error:", error);
    } finally {
      setLoading(false);
    }
  };

  const fetchQuestionSubjects = async () => {
    setLoadingSubjects(true);
    try {
      const { data } = await apiClient.get(
        "admin_creative_get_question_subjects",
        {
          headers: {
            request: JSON.stringify({ creative_id: account?.account_id }),
          },
        }
      );
      setSubjects(data.message || []);
    } catch (error) {
      console.log("Error:", error);
    } finally {
      setLoadingSubjects(false);
    }
  };

  return (
    <div>
      <Breadcrumb className="mb-4">
        <BreadcrumbList>
          <BreadcrumbItem>
            <Link to="/">Home</Link>
          </BreadcrumbItem>
          <BreadcrumbSeparator />
          <BreadcrumbItem>
            <Link to="/admin/agencies">{agency?.agency_name}</Link>
          </BreadcrumbItem>
          <BreadcrumbSeparator />
          <BreadcrumbItem>
            <Link to="/admin/clients">{client?.customer_name}</Link>
          </BreadcrumbItem>
          <BreadcrumbSeparator />
          <BreadcrumbItem>
            <Link to="/admin/accounts">{account?.account_name}</Link>
          </BreadcrumbItem>
          <BreadcrumbSeparator />
          <BreadcrumbItem>
            <BreadcrumbPage>Question schedules</BreadcrumbPage>
          </BreadcrumbItem>
        </BreadcrumbList>
      </Breadcrumb>

      <div className="space-y-2">
        <div className="flex justify-between items-center">
          <h3 className="scroll-m-20 text-2xl font-bold tracking-tight">
            Question schedules
          </h3>
          <AccountSelect />
        </div>
      </div>

      <div className="mt-8">
        <div className="flex justify-between items-center">
          <div className="flex items-center space-x-4">
            <p className="text-sm hidden md:inline-block text-secondary-foreground font-bold">
              Question subject
            </p>
            <Popover open={openSubject} onOpenChange={setOpenSubject}>
              <PopoverTrigger asChild disabled={loading}>
                <Button
                  variant="outline"
                  size="sm"
                  className="w-[150px] justify-start"
                >
                  <span className="truncate">
                    {question_subject?.question_subject_prompt}
                  </span>

                  <CaretSortIcon className="mr-2 h-4 w-4 ml-auto" />
                </Button>
              </PopoverTrigger>
              <PopoverContent className="p-0" side="right" align="start">
                <Command>
                  <CommandInput placeholder="Search account..." />
                  <CommandList>
                    <CommandEmpty>No results found.</CommandEmpty>
                    <CommandGroup>
                      {loadingSubjects ? (
                        <CommandItem>Loading...</CommandItem>
                      ) : (
                        subjects.map((question: QuestionSubject) => (
                          <CommandItem
                            key={question.question_subject_id}
                            value={String(question.question_subject_prompt)}
                            onSelect={() => {
                              dispatch({
                                type: "SET_ADMIN_QUESTION_SUBJECT",
                                payload: question,
                              });
                              setOpenSubject(false);
                            }}
                          >
                            <Check
                              className={cn(
                                "mr-2 h-4 w-4",
                                question.question_subject_id ===
                                  question_subject?.question_subject_id
                                  ? "opacity-100"
                                  : "opacity-0"
                              )}
                            />
                            <span>{question.question_subject_prompt}</span>
                          </CommandItem>
                        ))
                      )}
                    </CommandGroup>
                  </CommandList>
                </Command>
              </PopoverContent>
            </Popover>
          </div>

          <Button
            size="sm"
            onClick={() => setOpenAddSetting(true)}
            disabled={!Boolean(question_subject)}
          >
            Add question schedule
          </Button>
        </div>
      </div>

      <DataTableQuestionSchedules
        data={data}
        loading={loading}
        fetchData={fetchQuestionSchedules}
      />

      <AddQuestionScheduleDialog
        fetchData={fetchQuestionSchedules}
        open={openAddSetting}
        onOpenChange={setOpenAddSetting}
      />
    </div>
  );
};

export default QuestionSchedules;
