import { AxiosResponse, InternalAxiosRequestConfig } from 'axios';
import { TOKEN_KEY } from 'constants/constants';
import { useToast } from 'components/ui/use-toast';
import apiClient from 'api';

export default function useAxiosInterceptors() {
  const { toast } = useToast(); // Access the toast context

  apiClient.interceptors.request.use(
    (config: InternalAxiosRequestConfig) => {
      const token = localStorage.getItem(TOKEN_KEY);

      if (config.headers) {
        if (!config.headers.token) {
          config.headers.token = token && JSON.parse(token);
        }

        config.headers['Content-Type'] = 'application/json; charset=utf-8';
      }
      return config;
    },
    (error) => {
      if (error?.response?.status === 401) {
        window.location.href = '/login';
      }
      return Promise.reject(error);
    }
  );

  apiClient.interceptors.response.use(
    (response: AxiosResponse) => response,
    (error) => {
      const statusCode = error?.response?.status;

      if (statusCode === 401) {
        window.location.href = '/login';
      } else if (statusCode === 403) {
        toast({
          title: 'Insufficient Access',
          description: 'You do not have enough access to perform this action.',
          variant: "destructive"
        });
      }
      return Promise.reject(error);
    }
  );
};