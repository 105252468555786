import React, { createContext, useContext, useEffect, useReducer } from "react";
import settingsReducer, {
  SettingsAction,
  SettingsState,
  initialSettingsState,
} from "./reducers/settings";
import authReducer, {
  AuthAction,
  AuthState,
  initialAuthState,
} from "./reducers/auth";
import userReducer, {
  UserAction,
  UserState,
  initialUserState,
} from "./reducers/user";
import apiClient from "api";
import { TOKEN_KEY } from "constants/constants";
import adminReducer, {
  AdminAction,
  AdminState,
  initialAdminState,
} from "./reducers/admin";

const initialState = {
  settings: initialSettingsState,
  auth: initialAuthState,
  user: initialUserState,
  admin: initialAdminState,
};

type InitialStateType = {
  settings: SettingsState;
  auth: AuthState;
  user: UserState;
  admin: AdminState;
};

const AppContext = createContext<{
  state: InitialStateType;
  dispatch: React.Dispatch<
    SettingsAction | AuthAction | UserAction | AdminAction
  >;
}>({
  state: initialState,
  dispatch: () => null,
});

interface AppProviderProps {
  children: React.ReactNode;
}

const mainReducer = (
  { auth, settings, user, admin }: InitialStateType,
  action: AuthAction | SettingsAction | UserAction | AdminAction
) => ({
  auth: authReducer(auth, action as AuthAction),
  settings: settingsReducer(settings, action as SettingsAction),
  user: userReducer(user, action as UserAction),
  admin: adminReducer(admin, action as AdminAction),
});

const AppProvider: React.FC<AppProviderProps> = ({ children }) => {
  const [state, dispatch] = useReducer(mainReducer, initialState);

  useEffect(() => {
    const timeout = setTimeout(() => {
      const token = localStorage.getItem(TOKEN_KEY);
      if (token) {
        refreshToken(token).then((isValid) => {
          dispatch({ type: "REFRESH_TOKEN", payload: true });
          if (isValid) {
            dispatch({
              type: "LOGIN",
              payload: { user: { token }, token },
            });
            dispatch({ type: "REFRESH_TOKEN", payload: false });
          } else {
            dispatch({ type: "LOGOUT" });
            localStorage.removeItem("USER_STATE");
            dispatch({ type: "REFRESH_TOKEN", payload: false });
          }
        });
      } else {
        dispatch({ type: "LOGOUT" });
        dispatch({ type: "REFRESH_TOKEN", payload: false });
        localStorage.removeItem("USER_STATE");
      }
    }, 100);

    return () => clearTimeout(timeout);
  }, []);

  const refreshToken = async (token: string): Promise<boolean> => {
    try {
      const { data } = await apiClient.post(
        "/creative_refresh_token",
        {},
        {
          headers: {
            token: JSON.parse(token),
          },
        }
      );
      dispatch({
        type: "SET_ADMIN",
        payload: data?.message.admin === 1 ? true : false,
      });
      if (data?.message && data?.message.status === "ok") {
        return true;
      } else {
        return false;
      }
    } catch (error) {
      dispatch({ type: "LOGOUT" });
      console.error("Token validation failed", error);
      localStorage.removeItem(TOKEN_KEY);
      return false;
    }
  };

  return (
    <AppContext.Provider value={{ state, dispatch }}>
      {children}
    </AppContext.Provider>
  );
};
export const useAppState = () => useContext(AppContext);

export default AppProvider;
