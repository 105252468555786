import * as React from "react";
import {
  ColumnDef,
  ColumnFiltersState,
  SortingState,
  VisibilityState,
  flexRender,
  getCoreRowModel,
  getFilteredRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  useReactTable,
} from "@tanstack/react-table";
import { Button } from "components/ui/button";
import { Checkbox } from "components/ui/checkbox";
import { Input } from "components/ui/input";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "components/ui/table";
import { AuthorList } from "types";
import { DataTableColumnHeader } from "components/Shared/AdminTableHeader";
import { useAppState } from "context/AppContext";
import apiClient from "api";
import { AdminTablePagination } from "components/Shared/AdminTablePagination";

type Props = {
  data: AuthorList[];
  loading: boolean;
  fetchData: () => void;
};

export function DataTableAuthors({ data, loading, fetchData }: Props) {
  const { state } = useAppState();
  const { account } = state.admin;

  const [isSaving, setIsSaving] = React.useState(false);
  const [rows, setRows] = React.useState<AuthorList[]>([]);
  const [sorting, setSorting] = React.useState<SortingState>([]);
  const [columnFilters, setColumnFilters] = React.useState<ColumnFiltersState>(
    []
  );
  const [columnVisibility, setColumnVisibility] =
    React.useState<VisibilityState>({});
  const [rowSelection, setRowSelection] = React.useState({});

  React.useEffect(() => {
    const initalSelection: { [key: string]: boolean } = {};
    data.forEach((row, index) => {
      if (row.is_author === 1) {
        initalSelection[index] = true;
      }
    });
    setRowSelection(initalSelection);
    setRows(data.map((row) => ({ ...row })));
  }, [data]);

  const columns: ColumnDef<AuthorList>[] = [
    {
      id: "select",
      header: "Author",
      cell: ({ row }) => (
        <Checkbox
          checked={row.original.is_author === 1}
          aria-label="Select row"
          onCheckedChange={(value) => {
            const newData = rows.map((d) => {
              if (d.id === row.original.id) {
                if (value) {
                  return { ...d, is_author: 1 };
                } else {
                  return { ...d, is_author: 0, guest: 0 };
                }
              }
              return d;
            });
            setRows(newData);
          }}
        />
      ),
      enableSorting: false,
      enableHiding: false,
    },
    {
      id: "guest",
      header: "Guest",
      cell: ({ row }) => (
        <Checkbox
          checked={row.original.guest === 1}
          onCheckedChange={(value) => {
            if (row.original.is_author === 0) return;
            const newData = rows.map((d) => {
              if (d.id === row.original.id) {
                return { ...d, guest: value ? 1 : 0 };
              }
              return d;
            });
            setRows(newData);
          }}
          aria-label="Select row"
        />
      ),
      enableSorting: false,
      enableHiding: false,
    },
    {
      accessorKey: "name",
      header: ({ column }) => (
        <DataTableColumnHeader column={column} title="Name" />
      ),
      cell: ({ row }) => (
        <a
          rel="noreferrer"
          href={row.original.link}
          target="_blank"
          className="hover:underline cursor-pointer dark:text-blue-400 text-blue-800"
        >
          {row.getValue("name")}
        </a>
      ),
    },
    {
      accessorKey: "username",
      header: ({ column }) => (
        <DataTableColumnHeader column={column} title="Username" />
      ),
      cell: ({ row }) => <div>{row.getValue("username")}</div>,
    },
    {
      accessorKey: "url",
      header: ({ column }) => (
        <DataTableColumnHeader column={column} title="URL" />
      ),
      cell: ({ row }) => <div>{row.getValue("url")}</div>,
    },
  ];

  const table = useReactTable({
    data: rows,
    columns,
    onSortingChange: setSorting,
    onColumnFiltersChange: setColumnFilters,
    getCoreRowModel: getCoreRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    onColumnVisibilityChange: setColumnVisibility,
    onRowSelectionChange: setRowSelection,
    state: {
      sorting,
      columnFilters,
      columnVisibility,
      rowSelection,
    },
  });

  const areArraysEqual = (arr1: any[], arr2: any[]): boolean => {
    if (arr1.length !== arr2.length) return false;

    for (let i = 0; i < arr1.length; i++) {
      const val1 = arr1[i];
      const val2 = arr2[i];

      const areObjects = isObject(val1) && isObject(val2);
      if (
        (areObjects && !areObjectsEqual(val1, val2)) ||
        (!areObjects && val1 !== val2)
      ) {
        return false;
      }
    }

    return true;
  };

  const isObject = (object: any) => {
    return object != null && typeof object === "object";
  };

  const areObjectsEqual = (obj1: any, obj2: any): boolean => {
    const keys1 = Object.keys(obj1);
    const keys2 = Object.keys(obj2);

    if (keys1.length !== keys2.length) return false;

    for (const key of keys1) {
      const val1 = obj1[key];
      const val2 = obj2[key];
      const areObjects = isObject(val1) && isObject(val2);
      if (
        (areObjects && !areObjectsEqual(val1, val2)) ||
        (!areObjects && val1 !== val2)
      ) {
        return false;
      }
    }

    return true;
  };

  const saveChanges = async () => {
    setIsSaving(true);
    try {
      await apiClient.post(
        "/admin_creative_author_update",
        {},
        {
          headers: {
            request: JSON.stringify({
              account_id: account?.account_id,
              authors: rows
                .filter((d) => d.is_author === 1)
                .map((d) => ({
                  name: d.name,
                  id: d.id,
                  guest: d.guest,
                })),
            }),
          },
        }
      );
      fetchData();
      setIsSaving(false);
    } catch (error) {
      setIsSaving(false);
    }
  };

  return (
    <div className="w-full">
      <div className="flex justify-between items-center py-4">
        <Input
          placeholder="Filter authors..."
          value={(table.getColumn("name")?.getFilterValue() as string) ?? ""}
          onChange={(event) =>
            table.getColumn("name")?.setFilterValue(event.target.value)
          }
          className="max-w-sm"
        />

        {!areArraysEqual(data, rows) && (
          <Button
            onClick={saveChanges}
            disabled={isSaving}
            variant={"secondary"}
          >
            {isSaving ? "Saving changes..." : "Save changes"}
          </Button>
        )}
      </div>
      <div className="rounded-md border">
        <Table>
          <TableHeader>
            {table.getHeaderGroups().map((headerGroup) => (
              <TableRow key={headerGroup.id}>
                {headerGroup.headers.map((header) => {
                  return (
                    <TableHead key={header.id}>
                      {header.isPlaceholder
                        ? null
                        : flexRender(
                            header.column.columnDef.header,
                            header.getContext()
                          )}
                    </TableHead>
                  );
                })}
              </TableRow>
            ))}
          </TableHeader>
          <TableBody>
            {table.getRowModel().rows?.length ? (
              table.getRowModel().rows.map((row) => (
                <TableRow
                  key={row.id}
                  data-state={row.getIsSelected() && "selected"}
                >
                  {row.getVisibleCells().map((cell) => (
                    <TableCell key={cell.id} className="py-0.5 text-xs">
                      {flexRender(
                        cell.column.columnDef.cell,
                        cell.getContext()
                      )}
                    </TableCell>
                  ))}
                </TableRow>
              ))
            ) : (
              <TableRow>
                <TableCell
                  colSpan={columns.length}
                  className="h-24 text-center"
                >
                  {loading ? (
                    <div className="min-h-20 flex items-center justify-center">
                      <div className="flex flex-col justify-center items-center">
                        <div className="size-8 border-2 border-t-2 border-muted border-t-primary rounded-full animate-spin"></div>
                        <span className="text-sm text-center mt-4 text-primary">
                          Loading authors...
                        </span>
                      </div>
                    </div>
                  ) : (
                    data.length === 0 && "No results."
                  )}
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </div>
      <AdminTablePagination table={table} />
    </div>
  );
}
