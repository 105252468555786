import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import {
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbList,
  BreadcrumbPage,
  BreadcrumbSeparator,
} from "components/ui/breadcrumb";
import {
  Dialog,
  DialogClose,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from "components/ui/dialog";
import { Input } from "components/ui/input";
import { Label } from "components/ui/label";
import { Button } from "components/ui/button";
import { useToast } from "components/ui/use-toast";
import { DataTableClient } from "./data";
import { useAppState } from "context/AppContext";
import { ClientList } from "types";
import apiClient from "api";

const Client = () => {
  const { toast } = useToast();
  const { state } = useAppState();
  const { agency } = state.admin;
  const [clients, setClients] = useState([]);
  const [loading, setLoading] = useState(true);
  const [clientName, setClientName] = useState("");
  const [openAddClient, setOpenAddClient] = useState(false);
  const [openEditClient, setOpenEditClient] = useState(false);
  const [editData, setEditData] = useState<ClientList | null>(null);

  useEffect(() => {
    if (!agency) return;

    const timeout = setTimeout(() => {
      getClients();
    }, 200);

    return () => clearTimeout(timeout);
    // eslint-disable-next-line
  }, [agency]);

  const getClients = async () => {
    try {
      const { data } = await apiClient.get("/creative_get_companies", {
        headers: {
          request: JSON.stringify({ agency_id: agency?.agency_id }),
        },
      });
      setClients(data?.message || []);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.log("Error", error);
    }
  };

  const handleCreateClient = async (e: React.FormEvent) => {
    e.preventDefault();
    try {
      await apiClient.post(
        "/admin_creative_add_client",
        {},
        {
          headers: {
            request: JSON.stringify({
              client_name: clientName,
              agency_id: agency?.agency_id,
            }),
          },
        }
      );
      getClients();
      setClientName("");
    } catch (error) {
      toast({
        description: "Something went wrong",
        variant: "destructive",
      });
    }
  };

  const handleEditClient = async (e: React.FormEvent) => {
    e.preventDefault();

    if (!editData) return;
    try {
      await apiClient.post(
        "/admin_creative_update_client",
        {},
        {
          headers: {
            request: JSON.stringify({
              client_id: editData.customer_id,
              client_name: clientName,
              agency_id: agency?.agency_id,
            }),
          },
        }
      );
      getClients();
      setClientName("");
      setEditData(null);
    } catch (error) {
      toast({
        description: "Something went wrong",
        variant: "destructive",
      });
    }
  };

  return (
    <div>
      <Breadcrumb className="mb-4">
        <BreadcrumbList>
          <BreadcrumbItem>
            <Link to="/">Home</Link>
          </BreadcrumbItem>
          <BreadcrumbSeparator />
          <BreadcrumbItem>
            <Link to="/admin/agencies">{agency?.agency_name}</Link>
          </BreadcrumbItem>
          <BreadcrumbSeparator />
          <BreadcrumbItem>
            <BreadcrumbPage>Clients</BreadcrumbPage>
          </BreadcrumbItem>
        </BreadcrumbList>
      </Breadcrumb>

      <div className="space-y-2">
        <h3 className="scroll-m-20 text-2xl font-bold tracking-tight">
          Clients
        </h3>
        <p className="text-base text-muted-foreground">
          Add edit/update and delete clients.
        </p>
      </div>

      <div className="">
        {!agency ? (
          <p className="text-xl text-muted-foreground text-center mt-10">
            Please select an agency to view all the clients.
          </p>
        ) : (
          <DataTableClient
            data={clients}
            loading={loading}
            fetchData={getClients}
            openAddClient={() => setOpenAddClient(true)}
            openEditClient={(client: ClientList) => {
              setClientName(client.customer_name);
              setEditData(client);
              setOpenEditClient(true);
            }}
          />
        )}
      </div>

      <Dialog open={openAddClient} onOpenChange={setOpenAddClient}>
        <DialogContent className="sm:max-w-lg">
          <DialogHeader>
            <DialogTitle>Add client</DialogTitle>
          </DialogHeader>
          <form onSubmit={handleCreateClient}>
            <div className="grid gap-4 py-4">
              <div className="grid grid-cols-4 items-center gap-4">
                <Label htmlFor="name" className="text-right">
                  Agency
                </Label>
                <Input
                  id="agency_name"
                  readOnly
                  defaultValue={agency?.agency_name}
                  className="col-span-3"
                />
              </div>
              <div className="grid grid-cols-4 items-center gap-4">
                <Label htmlFor="username" className="text-right">
                  Client name
                </Label>

                <Input
                  id="client_name"
                  value={clientName}
                  autoFocus
                  required
                  className="col-span-3"
                  placeholder="e.g. Client 1"
                  onChange={(evt) => {
                    setClientName(evt.target.value);
                  }}
                />
              </div>
            </div>
            <DialogFooter>
              <DialogClose asChild>
                <Button type="submit">Save</Button>
              </DialogClose>
            </DialogFooter>
          </form>
        </DialogContent>
      </Dialog>

      <Dialog open={openEditClient} onOpenChange={setOpenEditClient}>
        <DialogContent className="sm:max-w-lg">
          <DialogHeader>
            <DialogTitle>Edit client name</DialogTitle>
          </DialogHeader>
          <form onSubmit={handleEditClient}>
            <div className="grid gap-4 py-4">
              <div className="grid grid-cols-4 items-center gap-4">
                <Label htmlFor="username" className="text-right">
                  Client name
                </Label>

                <Input
                  id="client_name"
                  value={clientName}
                  autoFocus
                  required
                  className="col-span-3"
                  placeholder="e.g. Client 1"
                  onChange={(evt) => {
                    setClientName(evt.target.value);
                  }}
                />
              </div>
            </div>
            <DialogFooter>
              <DialogClose asChild>
                <Button type="submit">Save</Button>
              </DialogClose>
            </DialogFooter>
          </form>
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default Client;
