import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import {
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbList,
  BreadcrumbPage,
  BreadcrumbSeparator,
} from "components/ui/breadcrumb";
import {
  Dialog,
  DialogClose,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from "components/ui/dialog";
import { Input } from "components/ui/input";
import { Label } from "components/ui/label";
import { Button } from "components/ui/button";
import { useToast } from "components/ui/use-toast";
import { DataTableAgency } from "./data";
import { AgencyList } from "types";
import apiClient from "api";

const Agency = () => {
  const { toast } = useToast();
  const [agencies, setAgencies] = useState([]);
  const [loading, setLoading] = useState(true);
  const [agencyName, setAgencyName] = useState("");
  const [editData, setEditData] = useState<AgencyList | null>(null);
  const [openAddAgency, setOpenAddAgency] = useState(false);
  const [openEditAgency, setOpenEditAgency] = useState(false);

  useEffect(() => {
    const timeout = setTimeout(() => {
      getAgencies();
    }, 200);

    return () => clearTimeout(timeout);
  }, []);

  const getAgencies = async () => {
    try {
      const { data } = await apiClient.get("/creative_get_agencies");
      setAgencies(data?.message || []);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.log("Error", error);
    }
  };

  const handleCreateAgency = async (e: React.FormEvent) => {
    e.preventDefault();
    try {
      await apiClient.post(
        "/admin_creative_add_agency",
        {},
        {
          headers: {
            request: JSON.stringify({
              agency_name: agencyName,
            }),
          },
        }
      );
      getAgencies();
      setAgencyName("");
    } catch (error) {
      toast({
        description: "Something went wrong",
        variant: "destructive",
      });
    }
  };

  const handleEditAgency = async (e: React.FormEvent) => {
    e.preventDefault();

    if (!editData) return;
    try {
      await apiClient.post(
        "/admin_creative_update_agency",
        {},
        {
          headers: {
            request: JSON.stringify({
              agency_id: editData.agency_id,
              agency_name: agencyName,
            }),
          },
        }
      );
      getAgencies();
      setAgencyName("");
      setEditData(null);
    } catch (error) {
      toast({
        description: "Something went wrong",
        variant: "destructive",
      });
    }
  };

  return (
    <div>
      <Breadcrumb className="mb-4">
        <BreadcrumbList>
          <BreadcrumbItem>
            <Link to="/">Home</Link>
          </BreadcrumbItem>
          <BreadcrumbSeparator />
          <BreadcrumbPage>Agencies</BreadcrumbPage>
        </BreadcrumbList>
      </Breadcrumb>

      <div className="space-y-2">
        <h3 className="scroll-m-20 text-2xl font-bold tracking-tight">
          Agencies
        </h3>
        <p className="text-base text-muted-foreground">
          Add edit/update agencies.
        </p>
      </div>

      <div>
        <DataTableAgency
          data={agencies}
          loading={loading}
          fetchData={getAgencies}
          openAddAgency={() => setOpenAddAgency(true)}
          openEditAgency={(agency: AgencyList) => {
            setAgencyName(agency.agency_name);
            setEditData(agency);
            setOpenEditAgency(true);
          }}
        />
      </div>

      <Dialog open={openAddAgency} onOpenChange={setOpenAddAgency}>
        <DialogContent className="sm:max-w-lg">
          <DialogHeader>
            <DialogTitle>Add agency</DialogTitle>
          </DialogHeader>
          <form onSubmit={handleCreateAgency}>
            <div className="grid gap-4 py-4">
              <div className="grid grid-cols-4 items-center gap-4">
                <Label htmlFor="username" className="text-right">
                  Agency name
                </Label>

                <Input
                  id="agency_name"
                  value={agencyName}
                  autoFocus
                  required
                  className="col-span-3"
                  placeholder="e.g. Agency 1"
                  onChange={(evt) => {
                    setAgencyName(evt.target.value);
                  }}
                />
              </div>
            </div>
            <DialogFooter>
              <DialogClose asChild>
                <Button type="submit">Save</Button>
              </DialogClose>
            </DialogFooter>
          </form>
        </DialogContent>
      </Dialog>

      <Dialog open={openEditAgency} onOpenChange={setOpenEditAgency}>
        <DialogContent className="sm:max-w-lg">
          <DialogHeader>
            <DialogTitle>Edit agency name</DialogTitle>
          </DialogHeader>
          <form onSubmit={handleEditAgency}>
            <div className="grid gap-4 py-4">
              <div className="grid grid-cols-4 items-center gap-4">
                <Label htmlFor="username" className="text-right">
                  Agency name
                </Label>

                <Input
                  id="agency_name"
                  value={agencyName}
                  autoFocus
                  required
                  className="col-span-3"
                  placeholder="e.g. Agency 1"
                  onChange={(evt) => {
                    setAgencyName(evt.target.value);
                  }}
                />
              </div>
            </div>
            <DialogFooter>
              <DialogClose asChild>
                <Button type="submit">Save</Button>
              </DialogClose>
            </DialogFooter>
          </form>
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default Agency;
