import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { CaretSortIcon, SymbolIcon } from "@radix-ui/react-icons";

import { Button, buttonVariants } from "components/ui/button";
import {
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbPage,
  BreadcrumbList,
  BreadcrumbSeparator,
} from "components/ui/breadcrumb";
import {
  DropdownMenu,
  DropdownMenuCheckboxItem,
  DropdownMenuContent,
  DropdownMenuGroup,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from "components/ui/dropdown-menu";
import { Popover, PopoverContent, PopoverTrigger } from "components/ui/popover";
import { Tooltip, TooltipContent, TooltipTrigger } from "components/ui/tooltip";
import { Label } from "components/ui/label";
import { ScrollArea } from "components/ui/scroll-area";

import { useConnection } from "context/ConnectionContext";
import { AccountList, AgencyList, ClientList } from "types";
import { useAppState } from "context/AppContext";
import { cn } from "lib/utils";
import apiClient from "api";

const Navigation = () => {
  const { state, dispatch } = useAppState();
  const { setIsOpen } = useConnection();
  // const { pathname } = useLocation();
  const { isAdmin } = state.auth;
  const { client, agency, account } = state.user;

  const [loadingAgencies, setLoadingAgencies] = useState(false);
  const [loadingClients, setLoadingClients] = useState(false);
  const [loadingAccounts, setLoadingAccounts] = useState(false);
  // const [loadingCampaigns, setLoadingCampaigns] = useState(false);

  const [clients, setClients] = useState<ClientList[]>([]);
  const [agencies, setAgencies] = useState<AgencyList[]>([]);
  const [accounts, setAccounts] = useState<AccountList[]>([]);
  // const [campaigns, setCampaigns] = useState<CampaignList[]>([]);

  // GET /creative_get_agencies  pass the token
  // GET /creative_get_companies  pass token and request  request holds {"agency_id": n}
  // GET /creative_get_accounts  pass token and request request holds {"company_id":n}
  // GET /creative_get_campaigns  pass token and request  request holds {"account_id":n}
  // GET /creative_connect_account pass in request {"target_account_id": n}
  // GET /creative_connect_campaign pass in request {"target_campaign_id": n}

  useEffect(() => {
    setLoadingAgencies(true);
    const getAgencies = async () => {
      try {
        const { data } = await apiClient.get("/creative_get_agencies");
        setAgencies(data?.message || []);
        setLoadingAgencies(false);
      } catch (error) {
        setLoadingAgencies(false);
        console.log("Error", error);
      }
    };

    const timeout = setTimeout(() => {
      getAgencies();
    }, 200);

    return () => clearTimeout(timeout);
  }, []);

  useEffect(() => {
    if (!agency) return;
    setLoadingClients(true);
    const getClients = async () => {
      try {
        const { data } = await apiClient.get("/creative_get_companies", {
          headers: {
            request: JSON.stringify({ agency_id: agency.agency_id }),
          },
        });
        setClients(data?.message || []);
        setLoadingClients(false);
      } catch (error) {
        setLoadingClients(false);
        console.log("Error", error);
      }
    };

    const timeout = setTimeout(() => {
      getClients();
    }, 200);

    return () => clearTimeout(timeout);
  }, [agency]);

  useEffect(() => {
    if (!client) return;
    setLoadingAccounts(true);
    const getAccounts = async () => {
      try {
        const { data } = await apiClient.get("/creative_get_accounts", {
          headers: {
            request: JSON.stringify({ company_id: client.customer_id }),
          },
        });
        setAccounts(data?.message || []);
        setLoadingAccounts(false);
      } catch (error) {
        setLoadingAccounts(false);
        console.log("Error", error);
      }
    };

    const timeout = setTimeout(() => {
      getAccounts();
    }, 200);

    return () => clearTimeout(timeout);
  }, [client]);

  useEffect(() => {
    if (!account) return;

    const connectAccount = async () => {
      try {
        const { data } = await apiClient.post(
          "/creative_connect_account",
          {},
          {
            headers: {
              request: JSON.stringify({
                target_account_id: account.account_id,
              }),
            },
          }
        );
        dispatch({
          type: "SET_ACCOUNT_TOKEN",
          payload: data?.message.account_token,
        });
        // getCampaigns();
      } catch (error) {
        console.log("Error", error);
      }
    };

    const timeout = setTimeout(() => {
      connectAccount();
    }, 200);

    return () => clearTimeout(timeout);
    // eslint-disable-next-line
  }, [account]);

  // const getCampaigns = async () => {
  //   setLoadingCampaigns(true);
  //   try {
  //     const { data } = await apiClient.get("/creative_get_campaigns", {
  //       headers: {
  //         request: JSON.stringify({ account_id: account?.account_id }),
  //       },
  //     });

  //     setLoadingCampaigns(false);
  //   } catch (error) {
  //     setLoadingCampaigns(false);
  //     console.log("Error", error);
  //   }
  // };

  // Set data
  const setAgency = (a: AgencyList | undefined) => {
    setClients([]);
    setAccounts([]);

    dispatch({ type: "SET_AGENCY", payload: a });
  };
  const setClinet = (c: ClientList | undefined) => {
    setAccounts([]);

    dispatch({ type: "SET_CLIENT", payload: c });
  };
  const setAccount = (a: AccountList | undefined) => {
    dispatch({ type: "SET_ACCOUNT", payload: a });
  };
  // const setCampaign = (c: CampaignList | undefined) => {
  //   dispatch({ type: "SET_CAMPAIGN", payload: c });
  // };

  return (
    <nav className="bg-background sticky top-0">
      <div className="flex flex-col lg:flex-row gap-2 lg:gap-0 justify-between py-3 px-4 border-b border-gray-200 dark:border-neutral-800">
        <div className="flex justify-between w-full">
          <div className="md:hidden block">
            <Popover>
              <PopoverTrigger asChild>
                <Button variant="outline" size="sm">
                  <span className="truncate max-w-[150px]">
                    {agency ? agency.agency_name : "Agency"}
                  </span>
                  <CaretSortIcon className="size-4 ml-2" />
                </Button>
              </PopoverTrigger>
              <PopoverContent className="w-80">
                <div className="grid gap-4">
                  {/* <div className="space-y-2">
                    <h4 className="font-medium leading-none">Dimensions</h4>
                    <p className="text-sm text-muted-foreground">
                      Set the dimensions for the layer.
                    </p>
                  </div> */}
                  <div className="grid gap-2">
                    <div className="grid grid-cols-3 items-center gap-4">
                      <Label htmlFor="agency">Agency</Label>
                      <DropdownMenu>
                        <DropdownMenuTrigger
                          id="agency"
                          className="flex items-center justify-between gap-1 col-span-2"
                        >
                          <div
                            className={cn(
                              buttonVariants({
                                variant: "outline",
                                size: "sm",
                              }),
                              "w-full h-8"
                            )}
                          >
                            <span className="truncate w-full">
                              {agency ? agency.agency_name : "Agency"}
                            </span>
                            {agencies.length === 0 ? (
                              <SymbolIcon className="ml-1 size-4 animate-spin" />
                            ) : (
                              <CaretSortIcon className="ml-1 size-4" />
                            )}
                          </div>
                        </DropdownMenuTrigger>
                        <DropdownMenuContent
                          align="start"
                          className="w-[200px]"
                        >
                          <ScrollArea className="h-52">
                            {agencies.map((a) => (
                              <DropdownMenuCheckboxItem
                                checked={agency?.agency_id === a.agency_id}
                                key={a.agency_id + "-" + a.agency_name}
                                onCheckedChange={(checked) => {
                                  if (checked) {
                                    setAgency(a);
                                    if (
                                      agency &&
                                      agency.agency_id !== a.agency_id
                                    ) {
                                      setClinet(undefined);
                                      setAccount(undefined);
                                    }
                                  } else {
                                    setAgency(undefined);
                                    setClinet(undefined);
                                    setAccount(undefined);
                                  }
                                }}
                              >
                                {a.agency_name}
                              </DropdownMenuCheckboxItem>
                            ))}
                          </ScrollArea>
                        </DropdownMenuContent>
                      </DropdownMenu>
                    </div>
                    {agency && (
                      <div className="grid grid-cols-3 items-center gap-4">
                        <Label htmlFor="client">Client</Label>
                        <DropdownMenu>
                          <DropdownMenuTrigger className="flex items-center justify-between gap-1 col-span-2">
                            <div
                              className={cn(
                                buttonVariants({
                                  variant: "outline",
                                  size: "sm",
                                }),
                                "h-8 w-full"
                              )}
                            >
                              <span className="truncate w-full">
                                {client ? client?.customer_name : "Client"}
                              </span>
                              {clients.length === 0 ? (
                                <SymbolIcon className="ml-1 size-4 animate-spin" />
                              ) : (
                                <CaretSortIcon className="ml-1 size-4" />
                              )}
                            </div>
                          </DropdownMenuTrigger>
                          <DropdownMenuContent
                            align="center"
                            className="w-[200px]"
                          >
                            <ScrollArea className="h-52">
                              {clients.map((c) => (
                                <DropdownMenuCheckboxItem
                                  key={c.customer_id + "-" + c.customer_name}
                                  checked={
                                    c.customer_id === client?.customer_id
                                  }
                                  onCheckedChange={(checked) => {
                                    if (checked) {
                                      setClinet(c);
                                      if (
                                        client &&
                                        c.customer_id !== client.customer_id
                                      ) {
                                        setAccount(undefined);
                                      }
                                    } else {
                                      setClinet(undefined);
                                      setAccount(undefined);
                                    }
                                  }}
                                >
                                  {c.customer_name}
                                </DropdownMenuCheckboxItem>
                              ))}
                            </ScrollArea>
                          </DropdownMenuContent>
                        </DropdownMenu>
                      </div>
                    )}
                    {client && (
                      <div className="grid grid-cols-3 items-center gap-4">
                        <Label htmlFor="account">Account</Label>
                        <DropdownMenu>
                          <DropdownMenuTrigger
                            id="account"
                            className="flex items-center justify-between gap-1 col-span-2"
                          >
                            <div
                              className={cn(
                                buttonVariants({
                                  variant: "outline",
                                  size: "sm",
                                }),
                                "h-8 w-full"
                              )}
                            >
                              <span className="truncate w-full">
                                {account ? account.account_name : "Account"}
                              </span>
                              {accounts.length === 0 ? (
                                <SymbolIcon className="ml-1 size-4 animate-spin" />
                              ) : (
                                <CaretSortIcon className="ml-1 size-4" />
                              )}
                            </div>
                          </DropdownMenuTrigger>
                          <DropdownMenuContent
                            align="center"
                            className="w-[200px]"
                          >
                            <ScrollArea className="h-52">
                              {accounts.map((a) => (
                                <DropdownMenuCheckboxItem
                                  key={a.account_id + "-" + a.account_id}
                                  checked={a.account_id === account?.account_id}
                                  onCheckedChange={(checked) => {
                                    if (checked) {
                                      setAccount(a);
                                    } else {
                                      setAccount(undefined);
                                    }
                                  }}
                                >
                                  {a.account_name}
                                </DropdownMenuCheckboxItem>
                              ))}
                            </ScrollArea>
                          </DropdownMenuContent>
                        </DropdownMenu>
                      </div>
                    )}
                  </div>
                </div>
              </PopoverContent>
            </Popover>
          </div>

          <Breadcrumb className="hidden md:block">
            <BreadcrumbList>
              <BreadcrumbItem>
                <BreadcrumbPage>
                  <DropdownMenu>
                    <DropdownMenuTrigger className="flex items-center justify-between gap-1">
                      <div
                        className={cn(
                          buttonVariants({ variant: "ghost", size: "sm" })
                        )}
                      >
                        {agency ? (
                          <Tooltip>
                            <TooltipTrigger
                              asChild
                              className="w-full max-w-[150px] truncate"
                            >
                              <span>{agency.agency_name}</span>
                            </TooltipTrigger>
                            <TooltipContent>
                              Agency: {agency.agency_name}
                            </TooltipContent>
                          </Tooltip>
                        ) : (
                          "Agency"
                        )}
                        {loadingAgencies ? (
                          <SymbolIcon className="ml-1 w-3 h-4 animate-spin" />
                        ) : (
                          <CaretSortIcon className="ml-1 w-3 h-4" />
                        )}
                      </div>
                    </DropdownMenuTrigger>
                    <DropdownMenuContent align="center" className="w-[200px]">
                      <DropdownMenuLabel>Agency</DropdownMenuLabel>
                      <ScrollArea className="h-52">
                        {agencies.map((a) => (
                          <DropdownMenuCheckboxItem
                            checked={agency?.agency_id === a.agency_id}
                            key={a.agency_id + "-" + a.agency_name}
                            onCheckedChange={(checked) => {
                              if (checked) {
                                setAgency(a);
                                if (
                                  agency &&
                                  agency.agency_id !== a.agency_id
                                ) {
                                  setClinet(undefined);
                                  setAccount(undefined);
                                }
                              } else {
                                setAgency(undefined);
                                setClinet(undefined);
                                setAccount(undefined);
                              }
                            }}
                          >
                            {a.agency_name}
                          </DropdownMenuCheckboxItem>
                        ))}
                      </ScrollArea>
                    </DropdownMenuContent>
                  </DropdownMenu>
                </BreadcrumbPage>
              </BreadcrumbItem>
              {agency && (
                <>
                  <BreadcrumbSeparator />
                  <BreadcrumbItem>
                    <BreadcrumbPage>
                      <DropdownMenu>
                        <DropdownMenuTrigger className="flex items-center justify-between gap-1">
                          <div
                            className={cn(
                              buttonVariants({
                                variant: "ghost",
                                size: "sm",
                              })
                            )}
                          >
                            {client ? (
                              <Tooltip>
                                <TooltipTrigger
                                  asChild
                                  className="w-full max-w-[150px] truncate"
                                >
                                  <span>{client?.customer_name}</span>
                                </TooltipTrigger>
                                <TooltipContent>
                                  Client: {client.customer_name}
                                </TooltipContent>
                              </Tooltip>
                            ) : (
                              "Client"
                            )}
                            {loadingClients ? (
                              <SymbolIcon className="ml-1 w-3 h-4 animate-spin" />
                            ) : (
                              <CaretSortIcon className="ml-1 w-3 h-4" />
                            )}
                          </div>
                        </DropdownMenuTrigger>
                        <DropdownMenuContent
                          align="center"
                          className="w-[200px]"
                        >
                          <DropdownMenuLabel>Client</DropdownMenuLabel>
                          <ScrollArea className="h-52">
                            {clients.map((c) => (
                              <DropdownMenuCheckboxItem
                                key={c.customer_id + "-" + c.customer_name}
                                checked={c.customer_id === client?.customer_id}
                                onCheckedChange={(checked) => {
                                  if (checked) {
                                    setClinet(c);
                                    if (
                                      client &&
                                      c.customer_id !== client.customer_id
                                    ) {
                                      setAccount(undefined);
                                    }
                                  } else {
                                    setClinet(undefined);
                                    setAccount(undefined);
                                  }
                                }}
                              >
                                {c.customer_name}
                              </DropdownMenuCheckboxItem>
                            ))}
                          </ScrollArea>
                        </DropdownMenuContent>
                      </DropdownMenu>
                    </BreadcrumbPage>
                  </BreadcrumbItem>
                </>
              )}

              {client && (
                <>
                  <BreadcrumbSeparator />
                  <BreadcrumbItem>
                    <BreadcrumbPage>
                      <DropdownMenu>
                        <DropdownMenuTrigger className="flex items-center justify-between gap-1">
                          <div
                            className={cn(
                              buttonVariants({
                                variant: "ghost",
                                size: "sm",
                              })
                            )}
                          >
                            {account ? (
                              <Tooltip>
                                <TooltipTrigger
                                  asChild
                                  className="w-full max-w-[150px] truncate"
                                >
                                  <span>{account.account_name}</span>
                                </TooltipTrigger>
                                <TooltipContent>
                                  Account: {account.account_name}
                                </TooltipContent>
                              </Tooltip>
                            ) : (
                              "Account"
                            )}
                            {loadingAccounts ? (
                              <SymbolIcon className="ml-1 w-3 h-4 animate-spin" />
                            ) : (
                              <CaretSortIcon className="ml-1 w-3 h-4" />
                            )}
                          </div>
                        </DropdownMenuTrigger>
                        <DropdownMenuContent
                          align="center"
                          className="w-[200px]"
                        >
                          <DropdownMenuLabel>Account</DropdownMenuLabel>
                          <ScrollArea className="h-52">
                            {accounts.map((a) => (
                              <DropdownMenuCheckboxItem
                                key={a.account_id + "-" + a.account_id}
                                checked={a.account_id === account?.account_id}
                                onCheckedChange={(checked) => {
                                  if (checked) {
                                    setAccount(a);
                                    if (
                                      account &&
                                      a.account_id !== account.account_id
                                    ) {
                                    }
                                  } else {
                                    setAccount(undefined);
                                  }
                                }}
                              >
                                {a.account_name}
                              </DropdownMenuCheckboxItem>
                            ))}
                          </ScrollArea>
                        </DropdownMenuContent>
                      </DropdownMenu>
                    </BreadcrumbPage>
                  </BreadcrumbItem>
                </>
              )}
              {/* {account && (
                <>
                  <BreadcrumbSeparator />
                  <BreadcrumbItem>
                    <BreadcrumbPage>
                      <DropdownMenu>
                        <DropdownMenuTrigger className="flex items-center justify-between gap-1 col-span-2">
                          <div
                            className={cn(
                              buttonVariants({
                                variant: "ghost",
                                size: "sm",
                              })
                            )}
                          >
                            {campaign ? (
                              <Tooltip>
                                <TooltipTrigger
                                  asChild
                                  className="w-full max-w-[150px] truncate "
                                >
                                  <span>{campaign.channel_name}</span>
                                </TooltipTrigger>
                                <TooltipContent>
                                  Campaign: {campaign.channel_name}
                                </TooltipContent>
                              </Tooltip>
                            ) : (
                              "Campaign"
                            )}

                            {loadingCampaigns ? (
                              <SymbolIcon className="ml-1 w-3 h-4 animate-spin" />
                            ) : (
                              <CaretSortIcon className="ml-1 w-3 h-4" />
                            )}
                          </div>
                        </DropdownMenuTrigger>
                        <DropdownMenuContent
                          align="center"
                          className="w-[200px]"
                        >
                          <DropdownMenuLabel>Campaign</DropdownMenuLabel>

                          <ScrollArea className="h-52">
                            {campaigns.length === 0 ? (
                              <DropdownMenuItem asChild>
                                <span>No campaigns.</span>
                              </DropdownMenuItem>
                            ) : (
                              campaigns.map((c) => (
                                <DropdownMenuCheckboxItem
                                  key={c.campaign_id + "-" + c.channel_name}
                                  checked={
                                    c.campaign_id === campaign?.campaign_id
                                  }
                                  onCheckedChange={(checked) => {
                                    if (checked) {
                                      
                                    } else {
                                      
                                    }
                                  }}
                                >
                                  {c.channel_name}
                                </DropdownMenuCheckboxItem>
                              ))
                            )}
                          </ScrollArea>
                        </DropdownMenuContent>
                      </DropdownMenu>
                    </BreadcrumbPage>
                  </BreadcrumbItem>
                </>
              )} */}
            </BreadcrumbList>
          </Breadcrumb>

          <div className="flex gap-2">
            {isAdmin && (
              <Link
                to="/admin"
                className={cn(
                  buttonVariants({ variant: "default", size: "sm" })
                )}
              >
                Admin dashboard
              </Link>
            )}

            {isAdmin && account && (
              <DropdownMenu>
                <DropdownMenuTrigger asChild>
                  <div
                    className={cn(
                      buttonVariants({ variant: "default", size: "sm" }),
                      "cursor-pointer"
                    )}
                  >
                    Manage account
                  </div>
                </DropdownMenuTrigger>

                <DropdownMenuContent className="w-36">
                  <DropdownMenuGroup>
                    <DropdownMenuItem asChild>
                      <Link to="/" className="flex items-center cursor-default">
                        Overview
                      </Link>
                    </DropdownMenuItem>
                    <DropdownMenuItem asChild>
                      <Link
                        to="/schedules"
                        className="flex items-center cursor-default"
                      >
                        Schedules
                      </Link>
                    </DropdownMenuItem>
                    <DropdownMenuItem asChild>
                      <Link
                        to="/connections"
                        className="flex items-center cursor-default"
                      >
                        Social connections
                      </Link>
                    </DropdownMenuItem>
                    <DropdownMenuItem asChild>
                      <Link
                        to="/websites"
                        className="flex items-center cursor-default"
                      >
                        Websites
                      </Link>
                    </DropdownMenuItem>
                    <DropdownMenuItem asChild>
                      <Link
                        to="/posts"
                        className="flex items-center cursor-default"
                      >
                        Posts
                      </Link>
                    </DropdownMenuItem>
                  </DropdownMenuGroup>
                  <DropdownMenuSeparator />
                  <DropdownMenuGroup>
                    <DropdownMenuItem asChild>
                      <Link
                        to="/schedules/setup"
                        className="flex items-center cursor-default"
                      >
                        Setup schedule
                      </Link>
                    </DropdownMenuItem>
                    <DropdownMenuItem onClick={() => setIsOpen(true)}>
                      New connection
                    </DropdownMenuItem>

                    <DropdownMenuItem asChild>
                      <Link
                        to="/posts/setup"
                        className="flex items-center cursor-default"
                      >
                        Make a post
                      </Link>
                    </DropdownMenuItem>
                  </DropdownMenuGroup>
                </DropdownMenuContent>
              </DropdownMenu>
            )}
          </div>
        </div>
      </div>

      {/* {account && (
        <div className="hidden md:block border-b border-gray-200 dark:border-neutral-800 py-4">
          <div className="px-4 md:px-6">
            <nav className="font-medium flex items-center text-sm gap-6">
              <Link
                to="/"
                className={cn(
                  "transition-colors hover:text-foreground",
                  pathname === "/" ? "text-foreground" : "text-muted-foreground"
                )}
              >
                Overview
              </Link>
              <Link
                to="/schedules"
                className={cn(
                  "transition-colors hover:text-foreground",
                  pathname === "/schedules"
                    ? "text-foreground"
                    : "text-muted-foreground"
                )}
              >
                Schedules
              </Link>
              <Link
                to="/connections"
                className={cn(
                  "transition-colors hover:text-foreground",
                  pathname === "/connections"
                    ? "text-foreground"
                    : "text-muted-foreground"
                )}
              >
                Social connections
              </Link>
              <Link
                to="/wordpress"
                className={cn(
                  "transition-colors hover:text-foreground",
                  pathname === "/wordpress"
                    ? "text-foreground"
                    : "text-muted-foreground"
                )}
              >
                Websites
              </Link>
              <Link
                to="/posts"
                className={cn(
                  "transition-colors hover:text-foreground",
                  pathname === "/posts"
                    ? "text-foreground"
                    : "text-muted-foreground"
                )}
              >
                Posts
              </Link>
            </nav>
          </div>
        </div>
      )} */}
    </nav>
  );
};

export default Navigation;
